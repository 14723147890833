import { colors } from '@chhjit/react-components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  pricesTitle: {
    textAlign: 'center',
  },
  pricesTitleSpan: {
    color: colors.primary.main,
  },
  pricesNotAvailable: {
    margin: '40px 0',
  },
  pricesTitleContainer: {
    marginBottom: 24,
  },
  pricesContainer: {
    marginBottom: 16,
  },
});
