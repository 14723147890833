import { memo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { Button } from '@chhjit/react-components';

import { AppRoute } from 'routing/AppRoute.enum';
import { PageContent } from 'ui/page-content/PageContent';
import { displayDate } from 'utils/formatDate/FormatDate';
import { DashedSeparator } from 'ui/dashedSeparator/DashedSeparator';
import { AppointmentsTableContainer } from 'app/appointmentsTable/AppointmentsTableContainer';
import { TruckLoadedIcon } from 'assets/icons/TruckLoadedIcon';

import { useStyles } from './PreCancelAppointment.styles';
import { CancelAppointmentProps } from './PreCancelAppointment.types';

const cons = [
  'Complimentary clean-up service for the job',
  'Licensed and bonded',
  'Extra packing supplies available for purchase',
  'Additional add-on labor and services are available to help complete any project',
];

export const PreCancelAppointment = memo(({ appointment }: CancelAppointmentProps) => {
  const styles = useStyles();
  const history = useHistory();

  const goHome = useCallback(
    () =>
      history.push({
        pathname: AppRoute.dashboard,
        state: { appointmentId: appointment.id },
      }),
    [appointment.id, history],
  );

  const goReschedule = useCallback(
    () =>
      history.push({
        pathname: AppRoute.reschedule,
        state: { appointment: appointment },
      }),
    [appointment, history],
  );

  const goCancel = useCallback(
    () =>
      history.replace({
        pathname: AppRoute.cancel,
        state: { appointment: appointment },
      }),
    [appointment, history],
  );

  return (
    <div data-testid="cancel" className={styles.root}>
      <PageContent
        title="Cancel appointment"
        subtitle={displayDate(appointment.startDate)}
        appointmentId={appointment.id}
        closable
      >
        <div className={styles.description}>
          <Typography variant="h4" color="primary">
            Was it something we said?
          </Typography>
          <br />
          <Typography>
            We don’t want this to be the end. We’re offering you a <b>$10 coupon</b> on your upcoming job to sweeten the
            deal.
          </Typography>
        </div>

        <div className={styles.cons}>
          <div>
            <TruckLoadedIcon />
          </div>
          <div>
            <Typography variant="h4" color="secondary">
              Why college hunks?
            </Typography>

            <ul className={styles.consList}>
              {cons.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        </div>

        <div className={styles.buttons}>
          <Button buttonType="twoTone" size="large" fullWidth onClick={goHome}>
            OK, I’ll keep my appointment
          </Button>
          <Button buttonType="outlined" size="large" fullWidth onClick={goReschedule}>
            Reschedule my appointment
          </Button>
          <Button size="large" fullWidth color="primary" onClick={goCancel}>
            I still want to cancel
          </Button>
        </div>

        <DashedSeparator />

        <AppointmentsTableContainer appointments={[appointment]} isLoading={false} isSingleAppointment />
      </PageContent>
    </div>
  );
});
