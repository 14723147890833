/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState, useEffect } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { Grid } from '@material-ui/core';
import { format, differenceInMinutes } from 'date-fns';
import { Redirect } from 'react-router-dom';
import { Loader } from '@chhjit/react-components';

import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { PageContent } from 'ui/page-content/PageContent';
import { displayDate } from 'utils/formatDate/FormatDate';
import { DashedSeparator } from 'ui/dashedSeparator/DashedSeparator';
import { AppointmentsTableContainer } from 'app/appointmentsTable/AppointmentsTableContainer';
import { checkAvailability } from 'api/actions/availability/AvailabilityActions';
import {
  AvailabilityRequest,
  AvailabilityResponse,
  AvailabilityResponseData,
} from 'api/actions/availability/AvailabilityActions.types';
import { checkServiceLocation } from 'api/actions/serviceCheck/ServiceCheckActions';
import { ServiceCheckResponse, ServiceCheckRequest } from 'api/actions/serviceCheck/ServiceCheckActions.types';
import { rescheduleAppointment } from 'api/actions/appointments/AppointmentsActions';
import { RescheduleAppointmentRequest, AppointmentsResponse } from 'api/actions/appointments/AppointmentsActions.types';
import { AppRoute } from 'routing/AppRoute.enum';
import { useMessagesDispatch } from 'hooks/useMessages/useMessages';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';

import { RescheduleAppointmentForm } from './rescheduleAppointmentForm/RescheduleAppointmentForm';
import { RescheduleAppointmentProps } from './RescheduleAppointment.types';
import { useStyles } from './RescheduleAppointment.styles';

export function RescheduleAppointment({ appointment }: RescheduleAppointmentProps) {
  const styles = useStyles();
  const { accountId, locationId, token } = useAuthState();
  const throwError = useAsyncError();
  const setMessage = useMessagesDispatch();
  const [zoneId, setZoneId] = useState<number>();
  const [success, setSuccess] = useState(false);
  const [notAvailable, setNotAvailable] = useState(false);
  const [isDuringRequest, setIsDuringRequest] = useState(false);

  const { query: checkServiceQuery } = useParameterizedQuery<ServiceCheckResponse>(checkServiceLocation);
  useEffect(() => {
    const checkService = async () => {
      const request: ServiceCheckRequest = {
        payload: { job_category_id: appointment.category.id, postal: appointment.origin.postal },
      };
      const { error, payload } = await checkServiceQuery(request);
      if (!error && payload && payload.locations && payload.locations[0]) {
        setZoneId(payload.locations[0].zone.id);
      } else {
        throwError(`Error on: ServiceCheckRequest, ${payload?.meta?.status?.description}`);
      }
    };
    if (appointment) {
      checkService();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment, throwError]);

  const handleCheckAvailability = async (date: Date, reCheck?: boolean): Promise<AvailabilityResponseData[]> => {
    setNotAvailable(false);
    const scheduleAvailability = await doCheckAvailability(date);
    return scheduleAvailability;
  };

  const { query: checkAvailabilityQuery } = useParameterizedQuery<AvailabilityResponse>(checkAvailability);
  const doCheckAvailability = async (date: Date) => {
    if (locationId && token && zoneId !== undefined) {
      const request: AvailabilityRequest = {
        path: { location_id: locationId },
        query: { token: token },
        payload: {
          type: appointment.type,
          category_id: appointment.category.id,
          view_date: format(date, 'yyyy-MM-dd HH:mm:ss'),
          duration: differenceInMinutes(appointment.endDate, appointment.startDate) / 60,
          number_of_days: 1,
          zone_id: zoneId.toString(),
        },
      };
      const { error, payload } = await checkAvailabilityQuery(request);
      if (!error && payload && payload.availability) {
        return payload.availability;
      } else {
        throwError(`Error on: AvailabilityRequest, ${payload?.meta?.status?.description}`);
      }
    }
    return [];
  };

  const { query: rescheduleAppointmentQuery } = useParameterizedQuery<AppointmentsResponse>(rescheduleAppointment);
  const handleSubmit = async (startDate: Date, endDate: Date, resourceId: number) => {
    if (accountId && locationId && token) {
      setIsDuringRequest(true);
      const availability = await doCheckAvailability(startDate);
      const isAvailable = !!availability.find(
        a =>
          a.schedule.start === format(startDate, 'yyyy-MM-dd HH:mm:ss') &&
          a.schedule.end === format(endDate, 'yyyy-MM-dd HH:mm:ss'),
      );
      if (isAvailable) {
        const request: RescheduleAppointmentRequest = {
          path: { location_id: locationId, appointment_id: appointment.id, account_id: accountId },
          query: { token: token },
          payload: {
            start_date: format(startDate, 'yyyy-MM-dd HH:mm:ss'),
            end_date: format(endDate, 'yyyy-MM-dd HH:mm:ss'),
            account: { id: accountId },
            resource: { id: resourceId },
          },
        };

        const { error, payload } = await rescheduleAppointmentQuery(request);
        if (!error && payload?.appointments) {
          setSuccess(true);
        } else {
          throwError(`Error on: AvailabilityRequest, ${payload?.meta?.status?.description}`);
        }
      } else {
        setNotAvailable(true);
      }
    }
    setIsDuringRequest(false);
  };

  if (success) {
    setMessage({ message: 'Appointment rescheduled', type: 'success' });
    return <Redirect to={{ pathname: AppRoute.dashboard, state: { appointmentId: appointment.id } }} />;
  }

  return (
    <div data-testid="reschedule" className={styles.root}>
      {zoneId !== undefined ? (
        <PageContent
          title="Reschedule appointment"
          subtitle={displayDate(appointment.startDate)}
          description="Here you can find a new date & time slot for your appointment"
          appointmentId={appointment.id}
          closable
        >
          <Grid container justify="center">
            <Grid item xs={12} className={styles.container}>
              <RescheduleAppointmentForm
                onSubmit={handleSubmit}
                onCheckAvailability={handleCheckAvailability}
                notAvailable={notAvailable}
                appointment={appointment}
                isDuringRequest={isDuringRequest}
              />
            </Grid>
          </Grid>
          <DashedSeparator />
          <AppointmentsTableContainer appointments={[appointment]} isLoading={false} isSingleAppointment />
        </PageContent>
      ) : (
        <Loader />
      )}
    </div>
  );
}
