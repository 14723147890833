import { Grid, Typography } from '@material-ui/core';
import { Button } from '@chhjit/react-components';

import { MailIcon } from 'assets/icons/MailIcon';
import { formatPhoneNumber } from 'utils/formatPhone/FormatPhone';

import { useStyles } from './FindAccountConfirmation.styles';
import { FindAccountConfirmationProps } from './FindAccountConfirmation.types';

export function FindAccountConfirmation({ email, phone, onResend, isDuringRequest }: FindAccountConfirmationProps) {
  const styles = useStyles();
  return (
    <Grid container className={styles.gridContainer} justify="center" alignItems="center">
      <Grid item className={styles.gridItem}>
        <MailIcon className={styles.icon} />
        <Typography variant="h1" className={styles.title}>
          {email ? 'Check your e-mail' : 'Check your text messages'}
        </Typography>
        <div className={styles.description}>
          <Typography variant="body1">We sent {email ? 'an email' : 'a text message'} to you at</Typography>
          <Typography variant="body1" color="secondary" className={styles.email}>
            {!phone ? email : formatPhoneNumber(phone)}
          </Typography>
          <Typography variant="body1">It has a magic link that’ll sign you in.</Typography>
        </div>
        <Typography variant="body1" className={styles.resendMessage}>
          Didn’t get the {email ? 'email' : 'text message'}?
        </Typography>
        <Button buttonType="text" onClick={onResend} isLoading={isDuringRequest}>
          <Typography variant="body1" color="secondary">
            Resend the link
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
