/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ReactNode, memo, useCallback, useEffect, useMemo, useReducer } from 'react';
import { useMutation, useParameterizedQuery } from 'react-fetching-library';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import { Loader } from '@chhjit/react-components';
import moment from 'moment';
import { useDebounce } from 'use-debounce';

import { quoteStateDefault } from '../quotesContext/QuotesContext';
import { QuoteStateContext, QuoteDispatchContext } from '../quotesContext/QuotesContext';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { QuoteContextReducer } from '../quotesContextReducer/QuotesContextReducer';
import {
  SET_APPOINTMENT,
  SET_AVAILABILITY,
  SET_LABOR_HOURS,
  SET_PRE_PAYMENT,
  SET_QUOTE,
  SET_QUOTE_LOG,
  SET_ZONE,
} from '../quotesContextReducer/QuotesContextReducer.types';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';
import { fetchQuote, fetchQuoteLog, quoteCalculate } from 'api/actions/quotes/QuotesActions';
import {
  QuoteCalculateRequest,
  QuoteCalculateResponse,
  QuoteLog,
  QuoteLogRequest,
  QuoteLogResponse,
  QuoteRequest,
} from 'api/actions/quotes/QuotesActions.types';
import { AppRoute } from 'routing/AppRoute.enum';
import { QuoteStateType } from '../quotesContext/QuotesContext.types';
import { useMessagesDispatch } from 'hooks/useMessages/useMessages';
import { checkServiceLocation } from 'api/actions/serviceCheck/ServiceCheckActions';
import { ServiceCheckRequest, ServiceCheckResponse } from 'api/actions/serviceCheck/ServiceCheckActions.types';

export const QuotesContextController = memo(({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const history = useHistory();
  const setMessage = useMessagesDispatch();

  const defaultState = useMemo(() => {
    const sessionStorageState = sessionStorage.getItem('quote-estimate');

    if (sessionStorageState) {
      const parcedState = JSON.parse(sessionStorageState);

      if (!parcedState.isBooked) {
        return { ...(parcedState as QuoteStateType), isLoading: true };
      } else if (!location.pathname.includes('/all-done')) {
        sessionStorage.setItem('quote-estimate', JSON.stringify(quoteStateDefault));
      }
    }

    return quoteStateDefault;
  }, [location]);

  const [state, setState] = useReducer(QuoteContextReducer, defaultState);

  const throwError = useAsyncError();
  const { accountId, locationId, token, isAuthorized, isAuthorizing, user } = useAuthState();
  const { quoteId } = useParams<{ quoteId: string }>();

  // getting quote log
  const { query } = useParameterizedQuery<QuoteLogResponse>(fetchQuoteLog);
  const getQuoteLog = useCallback(
    async (logId: number) => {
      if (token && locationId && accountId && quoteId) {
        const request: QuoteLogRequest = {
          path: { account_id: accountId, quote_id: Number(quoteId), log_id: logId },
          query: { token },
        };

        const { error, payload } = await query(request);

        if (!error && payload?.quote_logs?.length) {
          let log = state.quoteLog;

          if (payload?.quote_logs[0].id !== state.quoteLog?.id || !state.quoteLog.signed) {
            log = payload?.quote_logs[0];
            setState({ type: SET_AVAILABILITY, payload: undefined });
          }

          setState({ type: SET_QUOTE_LOG, payload: log as QuoteLog });
        } else {
          throwError(`Error on: QuoteRatesRequest, ${payload?.meta?.status?.description}`);
        }
      }
    },
    [accountId, locationId, query, quoteId, state.quoteLog, throwError, token],
  );

  // getting quote
  const { query: getQuoteQuery } = useParameterizedQuery(fetchQuote);
  const getQuote = useCallback(
    async (id: number) => {
      try {
        if (accountId && locationId && token) {
          const request: QuoteRequest = {
            path: { account_id: accountId, location_id: locationId, quote_id: id },
            query: { token: token },
          };

          const { payload } = await getQuoteQuery(request);

          if (payload?.quotes?.length) {
            const quote = payload.quotes[0];

            if (quote.job.id && !location.pathname.includes('/all-done')) {
              sessionStorage.removeItem('quote-estimate');
              setMessage({ message: 'Estimate has already been booked.', type: 'warning' });

              return history.push(AppRoute.dashboard);
            } else {
              if (quote.id !== state.quote?.id) {
                await setState({
                  type: SET_APPOINTMENT,
                  payload: null,
                });
              }

              setState({
                type: SET_QUOTE,
                payload: quote,
              });

              let logId = quote.quote_logs.find(item => item.signed)?.id;

              if (!logId) {
                const sorted = quote.quote_logs.sort((a, b) => moment(b.datetime).diff(moment(a.datetime)));
                logId = sorted[0]?.id;
              }

              await getQuoteLog(logId);
            }
          } else {
            throwError(`Error on: QuoteRequest, ${payload?.meta.status.description}`);
          }
        }
      } catch (error) {
        throwError(`Error on: QuoteRequest, ${error}`);
      }
    },
    [
      accountId,
      getQuoteLog,
      getQuoteQuery,
      history,
      location.pathname,
      locationId,
      setMessage,
      state.quote?.id,
      throwError,
      token,
    ],
  );

  useEffect(() => {
    if (!isAuthorizing && isAuthorized && quoteId) {
      getQuote(Number(quoteId));
    }
  }, [isAuthorized, isAuthorizing, quoteId]);

  // Calculate request
  const { mutate: quoteCalculateQuery, loading: isCalculating } = useMutation<QuoteCalculateResponse>(quoteCalculate);
  const calculate = useCallback(async () => {
    if (accountId && locationId && token && state.quote?.id && user) {
      const request: QuoteCalculateRequest = {
        path: { account_id: accountId, location_id: locationId },
        query: { token: token },
        payload: {
          rooms: state.quote.rooms,
          appliances: state.quote.appliances,
          assembly_items: state.quote.assembly_items,
          heavy_items: state.quote.heavy_items,
          hunks: state.quote.hunks,
          force_hunks: true,
          date: state.quote.date,
          number_of_boxes: state.quote.number_of_boxes,
          location: {
            id: state.quote.location.id,
          },
          category: {
            id: state.quote.category.id,
          },
          work_type: {
            id: state.quote.work_type.id,
          },
          source: {
            id: user.source.id,
          },
          type: {
            id: state.quote.type.id,
          },
          account: {
            id: state.quote.account.id,
          },
          origin: state.quote.origin,
          destination: state.quote.destination,
          stops: state.quote.stops,
          // @ts-ignore
          inventory: state.quote.inventory,
          // @ts-ignore
          custom_items: state.quote.custom_items,
          // @ts-ignore
          spaces: state.quote.spaces,
          // @ts-ignore
          pricing: state.quote.pricing,
        },
      };
      const { error, payload } = await quoteCalculateQuery(request);

      if (!error && payload && payload.quote.length) {
        setState({
          type: SET_PRE_PAYMENT,
          payload: payload.quote[0].pre_payment,
        });
        setState({
          type: SET_LABOR_HOURS,
          payload: payload.quote[0].labor_hours,
        });
      } else {
        throwError(`Error on: CalculateRequest, ${payload?.meta?.status?.description}`);
      }
    }
  }, [accountId, locationId, quoteCalculateQuery, state.quote, throwError, token, user]);

  // Calculate request
  const { query: checkServiceQuery } = useParameterizedQuery<ServiceCheckResponse>(checkServiceLocation);

  const checkService = useCallback(async () => {
    if (accountId && locationId && token && state.quote?.id && user) {
      const request: ServiceCheckRequest = {
        payload: { job_category_id: state.quote.category.id, postal: state.quote.origin.postal },
      };
      const { error, payload } = await checkServiceQuery(request);

      if (!error && payload && payload.locations.length) {
        const location = payload.locations.find(location => location.id === locationId);

        setState({
          type: SET_ZONE,
          payload: location?.zone.id || 0,
        });
      } else {
        throwError(`Error on: ServiceCheckRequest, ${payload?.meta?.status?.description}`);
      }
    }
  }, [accountId, checkServiceQuery, locationId, state.quote, throwError, token, user]);

  useEffect(() => {
    if (state.quote?.id && !state.isLoading) {
      checkService();
      calculate();
    }
  }, [state.isLoading, state.quote?.id]);

  const [loadingDebounce] = useDebounce(state.isLoading || isCalculating || isAuthorizing, 100);

  useEffect(() => {
    sessionStorage.setItem('quote-estimate', JSON.stringify(state));
  }, [state]);

  if (!isAuthorizing && !isAuthorized) {
    return <Redirect to={AppRoute.findAccount} />;
  }

  if (loadingDebounce) {
    return <Loader />;
  }

  return (
    <QuoteStateContext.Provider value={state}>
      <QuoteDispatchContext.Provider value={setState}>{children}</QuoteDispatchContext.Provider>
    </QuoteStateContext.Provider>
  );
});
