import { useDialogDispatch } from '@chhjit/react-components';
import { memo } from 'react';

import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { Prices } from 'app/prices/Prices';
import { MoneyBagIcon } from 'assets/icons/MoneyBagIcon';
import { ActionButton } from 'ui/actionButton/ActionButton';

export const AppointmentPricingButton = memo(({ appointment }: { appointment: Appointment }) => {
  const setDialog = useDialogDispatch();

  const handleOpenDialog = () => {
    setDialog({
      open: true,
      variant: 'schedule',
      dialogContent: <Prices appointment={appointment} jobDate={appointment.startDate} />,
    });
  };

  return <ActionButton icon={<MoneyBagIcon />} text="See pricing" onClick={() => handleOpenDialog()} />;
});
