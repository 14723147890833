import { makeStyles, Theme } from '@material-ui/core';

import { theme } from 'assets/theme/Theme';

interface StyleProps {
  allowRefund?: boolean;
}

export const useStyles = makeStyles<Theme, StyleProps>(() => ({
  selectContainer: {
    margin: ({ allowRefund }) => (allowRefund ? '32px 0 0' : '100px 0 0'),

    [theme.breakpoints.down('sm')]: {
      margin: '24px 0 0',
    },
  },

  helperContainer: {
    position: 'absolute',
    top: '100%',
  },

  formControl: {
    marginBottom: '40px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px',
    },
  },

  refundText: {
    margin: '40px 0 0',
  },

  buttonContainer: {
    margin: ({ allowRefund }) => (allowRefund ? '0 0 50px' : '32px 0'),
    textAlign: 'center',
  },
}));
