import { memo, useCallback, useState } from 'react';
import { Grid, MenuItem, Typography } from '@material-ui/core';
import { Button, Select } from '@chhjit/react-components';

import { useStyles } from './CancelAppointmentForm.styles';
import { CancelAppointmentFormProps } from './CancelAppointmentForm.types';

export const CancelAppointmentForm = memo(
  ({ isDuringRequest, onCancel, allowRefund, refundAmount }: CancelAppointmentFormProps) => {
    const styles = useStyles({ allowRefund });
    const [reason, setReason] = useState(0);

    const handleSubmit = useCallback(() => {
      if (reason) {
        onCancel(reason);
      }
    }, [onCancel, reason]);

    return (
      <form>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={10} md={7} lg={7} xl={7}>
            <div className={styles.selectContainer}>
              <Select
                fullWidth
                onChange={({ target }) => setReason(target.value as number)}
                value={reason}
                size="large"
              >
                <MenuItem value={0} disabled>
                  <span className="select-placeholder">– select reason of cancellation –</span>
                </MenuItem>
                {cancelReasons.map(reason => (
                  <MenuItem value={reason.id} key={reason.id}>
                    {reason.displayName}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </Grid>

          {!!refundAmount && (
            <Grid item xs={12}>
              <Typography align="center" className={styles.refundText}>
                Your deposit of ${refundAmount.toFixed(2)} will be refunded to you. Please allow 7-10 business days to
                see this posted to your bank account statements.
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={10} md={7} lg={7} xl={7}>
            <div className={styles.buttonContainer}>
              <Button
                disabled={reason === 0}
                buttonType="twoTone"
                size="large"
                isLoading={isDuringRequest}
                onClick={() => handleSubmit()}
                fullWidth={allowRefund}
              >
                Cancel appointment
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    );
  },
);

const cancelReasons: Reason[] = [
  { id: 1, displayName: 'I made other arrangements' },
  { id: 28, displayName: 'I am not ready for the appointment' },
  { id: 36, displayName: 'The date I wanted is not available' },
  { id: 30, displayName: 'Service is too expensive' },
  { id: 32, displayName: 'Other' },
];

export type Reason = {
  id: number;
  displayName: string;
};
