import { ApiRequestPath, ApiRequestQuery, ApiResponseBase } from 'api/types/api';

export type LocationsRequest = {
  path: Pick<ApiRequestPath, 'location_id' | 'account_id'>;
  query: Pick<ApiRequestQuery, 'token'>;
};

export type MoveSettings = {
  location: {
    id: number;
    name: string;
  };
  minimum_differential: number;
  maximum_drive_time: number;
  truck_and_travel: {
    move: {
      two_hunks: number;
      three_hunks: number;
      four_hunks: number;
      five_hunks: number;
      six_hunks: number;
      flat_fee: number;
    };
    move_labor: {
      two_hunks: number;
      three_hunks: number;
      four_hunks: number;
      five_hunks: number;
      six_hunks: number;
      flat_fee: number;
    };
  };
  pre_payment: {
    allow_auto_refund: boolean;
    move: {
      refund_days: number;
      require: number;
    };
    move_labor: {
      refund_days: number;
      require: number;
    };
    general_labor: {
      refund_days: number;
      require: number;
    };
    collect_slc: number;
  };
  quote_range_type: number;
  hours_calculation: {
    moving: {
      type: number;
      two_hunks: number;
      three_hunks: number;
      four_hunks: number;
      five_hunks: number;
      six_hunks: number;
    };
    packing: {
      type: number;
      two_hunks: number;
      three_hunks: number;
      four_hunks: number;
      five_hunks: number;
      six_hunks: number;
    };
  };
  interstate_move_onsite: number;
  slc_three_bedroom_quotes: number;
  num_hunks_one_bedroom: number;
  num_hunks_two_bedroom: number;
  num_hunks_three_bedroom: number;
  special_formula: {
    active: number;
    instructions: {
      stress_free: string;
      inventory: string;
    };
  };
  indicators: {
    assembly: number;
    appliances: number;
    heavy: number;
    long_walks: {
      one_bedroom: number;
      two_bedroom: number;
      three_bedroom: number;
    };
    stairs: {
      one_bedroom: number;
      two_bedroom: number;
      three_bedroom: number;
    };
    elevators: {
      one_bedroom: number;
      two_bedroom: number;
      three_bedroom: number;
    };
    three_hunks: {
      stairs: boolean;
      elevators: boolean;
      long_walks: boolean;
      long_move: boolean;
    };
  };
  quote_customizations: {
    inventory: {
      quote_body: string;
      quote_welcome: string;
      quote_signature: string;
      require: number;
    };
    reply: {
      email: string;
      user: number;
    };
  };
};

export type LocationResponseData = {
  payment_vendor: {
    id: number;
    name: string;
  };
  integrations: {
    hunkpay: {
      valid: boolean;
    };
    square: {
      active: boolean;
      valid: true;
    };
  };
  move_settings: MoveSettings[];
};

export type LocationResponse = {
  locations: LocationResponseData[];
} & ApiResponseBase;

export enum PaymentVendorEnum {
  Automatic = 1,
  HunkPay = 2,
  Square = 3,
}
