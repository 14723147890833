import { colors } from '@chhjit/react-components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  dialogContainer: {
    textAlign: 'center',
    overflowY: 'scroll',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  subtitle: {
    color: colors.primary.main,
  },
  dialogHeader: {
    width: '100%',
  },
  feedbackInput: {
    width: '100%',
  },
  reviewPlatform: {
    padding: '10px',
    textAlign: 'left',
    background: '#F6F7F9',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: '0px 0px 16px rgba(134, 145, 166, 0.16);',
      background: colors.white,
    },
    borderRadius: '4px',
  },
  socialLogo: {
    height: '40px',
    verticalAlign: 'middle',
  },
  reviewPlatformItem: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
});
