import { colors } from '@chhjit/react-components';
import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },

  description: {
    textAlign: 'center',
    margin: '96px auto 0',
    maxWidth: 640,

    [theme.breakpoints.down('sm')]: {
      margin: '24px auto 0',
    },
  },

  cons: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    maxWidth: 760,
    margin: '32px auto',
    border: `3px dashed ${colors.grey30}`,
    borderRadius: 4,
    padding: 16,
    position: 'relative',

    '& *': { zIndex: 1 },

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: colors.grey20,
      zIndex: 0,
    },
  },

  consList: {
    paddingLeft: 24,
    margin: '8px 0 0',
    fontSize: 18,
    fontWeight: 700,
    color: colors.grey160,
  },

  buttons: {
    maxWidth: 460,
    margin: '48px auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 24,

    [theme.breakpoints.down('sm')]: {
      margin: '24px auto',
    },
  },
}));
