export enum AppRoute {
  home = '/',
  findAccount = '/find-account',
  dashboard = '/dashboard',
  login = '/login',
  preCancel = '/pre-cancel',
  cancel = '/cancel',
  reschedule = '/reschedule',
  chhj = '/home',
  booking = '/booking',
  estimate = '/estimate',
  quotes = '/quotes',
}
