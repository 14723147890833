export type CustomErrorAction = {
  title: string;
  onClick: () => void;
};

export type CustomErrorOptions = {
  description?: string;
  details?: string;
  action?: CustomErrorAction;
};

export class CustomError extends Error {
  public readonly description?: string;
  public readonly details?: string;
  public readonly action?: CustomErrorAction;

  constructor(message: string, options?: CustomErrorOptions) {
    super(message);

    this.name = 'CustomError';

    this.description = options?.description;
    this.details = options?.details;
    this.action = options?.action;

    Object.setPrototypeOf(this, CustomError.prototype);
  }
}
