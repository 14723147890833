import { colors } from '@chhjit/react-components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  canvas: {
    backgroundColor: colors.grey10,
    border: `1px solid ${colors.grey30}`,
    width: '100%',
    aspectRatio: 4 / 3,
  },
}));
