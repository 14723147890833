/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useState, useRef, useEffect } from 'react';
import { Grid, Typography, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { Button, Input, Select } from '@chhjit/react-components';

import { FindAccountPayload } from 'api/actions/findAccount/FindAccountActions.types';
import { getAppointmentCategories } from 'api/types/appointment';
import { validateEmail, validatePhone } from 'utils/validate/Validate';

import { useStyles } from './FindAccountForm.styles';
import { FindAccountFormProps, FindAccountFormData } from './FindAccountForm.types';

export function FindAccountForm({ onSubmit, isDuringRequest }: FindAccountFormProps) {
  const styles = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    clearErrors,
  } = useForm<FindAccountFormData>({ mode: 'onChange' });
  const [error, setError] = useState('');
  const isMounted = useRef(true);

  const categories = getAppointmentCategories();

  const handleSubmitCallback = useCallback(
    async (body: FindAccountFormData) => {
      const isEmail = validateEmail(body.emailOrPhone);
      const request: FindAccountPayload = isEmail
        ? {
            email: body.emailOrPhone.trim(),
            postal: body.postal.trim(),
            category: { id: body.category },
          }
        : {
            phone: body.emailOrPhone.replace(/\D+/g, ''),
            postal: body.postal.trim(),
            category: { id: body.category },
          };
      const errorMessage = await onSubmit(request);
      if (!isMounted.current) return;
      if (!!errorMessage) {
        setError(errorMessage);
      } else {
        setError('');
      }
    },
    [onSubmit],
  );

  // on un-mount
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Grid container className={styles.gridContainer} justify="center" alignItems="center">
      <Grid item className={styles.gridItem}>
        <Typography variant="h2" color="secondary">
          Manage Your Appointment
        </Typography>
        <Typography variant="body1" className={styles.description}>
          Enter your details
          <br /> to manage your appointment.
        </Typography>

        <form className={styles.form} onSubmit={handleSubmit(handleSubmitCallback)}>
          <FormControl
            className={styles.formControl}
            error={!!errors.emailOrPhone?.message || !!error}
            onInput={() => {
              setError('');
              clearErrors('emailOrPhone');
            }}
          >
            <Input
              {...register('emailOrPhone', {
                required: 'This field is required',
                validate: value =>
                  validateEmail(value) || validatePhone(value) || 'Please provide correct phone number or email!',
              })}
              placeholder="Your e-mail or phone number"
              fullWidth
            />

            <div className={styles.helperContainer}>
              <FormHelperText>{errors.emailOrPhone && errors.emailOrPhone.message}</FormHelperText>
              <FormHelperText>{!!error && error}</FormHelperText>
            </div>
          </FormControl>

          <FormControl className={styles.formControl} error={!!errors.category?.message}>
            <Controller
              name="category"
              control={control}
              defaultValue={0}
              rules={{
                validate: value => (!value ? 'Choose Job category' : true),
              }}
              render={({ field, fieldState }) => (
                <Select {...field} fullWidth error={!!fieldState.error}>
                  <MenuItem value={0} disabled>
                    <span className="select-placeholder">What went wrong?</span>
                  </MenuItem>
                  {Object.entries(categories).map(([key, category]) => (
                    <MenuItem value={category.id} key={category.id}>
                      {category.displayName}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

            <div className={styles.helperContainer}>
              <FormHelperText>{errors.category && errors.category.message}</FormHelperText>
            </div>
          </FormControl>

          <FormControl className={styles.formControl} error={!!errors.postal?.message}>
            <Input
              {...register('postal', {
                required: 'This field is required',
              })}
              placeholder="Zip/postal code"
              fullWidth
            />

            <div className={styles.helperContainer}>
              <FormHelperText>{errors.postal && errors.postal.message}</FormHelperText>
            </div>
          </FormControl>

          <Button buttonType="twoTone" size="large" type="submit" isLoading={isDuringRequest} fullWidth>
            Login
          </Button>
        </form>
      </Grid>
    </Grid>
  );
}
