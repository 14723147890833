import { colors } from '@chhjit/react-components';
import { Theme, makeStyles } from '@material-ui/core';

export const useAvailibilityColumnStyles = makeStyles((theme: Theme) => ({
  column: { paddingRight: 12 },

  hourLabel: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 16,

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },

  noHours: {
    color: colors.grey80,
  },
}));
