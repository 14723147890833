import { memo, useState } from 'react';
import { Typography, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Redirect } from 'react-router-dom';
import { Button } from '@chhjit/react-components';
import clsx from 'clsx';

import { Logout } from 'app/logout/Logout';
import { AppRoute } from 'routing/AppRoute.enum';
import { ScrollToTop } from 'ui/scrollToTop/ScrollToTop';
import { DashboardProps } from 'app/dashboard/Dashboard.types';

import { useStyles } from './PageContent.styles';
import { PageContentProps } from './PageContent.types';

export const PageContent = memo(
  ({ title, subtitle, description, closable, children, appointmentId, variant }: PageContentProps) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const styles = useStyles();

    const [redirect, setRedirect] = useState(false);

    if (redirect) {
      return (
        <Redirect to={{ pathname: AppRoute.dashboard, state: { appointmentId: appointmentId } as DashboardProps }} />
      );
    }

    return (
      <div className={clsx(styles.root, variant === 'secondary' && styles.zeroPadding)} data-testid="page-content">
        <ScrollToTop />

        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={10} lg={8} xl={6}>
            <div className={styles.container}>
              {closable && (
                <div className={styles.closeContainer}>
                  <Button buttonType="twoTone" size="medium" onClick={() => setRedirect(true)}>
                    <CloseIcon />
                  </Button>
                </div>
              )}

              <div className={styles.logoutContainer}>
                <Logout />
              </div>

              <div className={styles.titleContainer}>
                <Typography className={styles.title} variant={isMobile ? 'h3' : 'h2'} color="secondary">
                  {title}
                </Typography>

                {subtitle && (
                  <>
                    <span> </span>
                    <Typography className={styles.subtitle} variant={isMobile ? 'h3' : 'h2'} color="primary">
                      {subtitle}
                    </Typography>
                  </>
                )}
              </div>
            </div>

            {description && (
              <Typography className={styles.description} variant="body1">
                {description}
              </Typography>
            )}

            {children}
          </Grid>
        </Grid>
      </div>
    );
  },
);
