import { memo, useState } from 'react';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Button, useDialogDispatch } from '@chhjit/react-components';

import { ReviewAppointmentNeutral } from 'app/appointmentManagement/finishedAppointmentManagementPanel/reviewAppointmentDialog/reviewAppointmentNeutral/ReviewAppointmentNeutral';
import { ReviewAppointmentAngry } from 'app/appointmentManagement/finishedAppointmentManagementPanel/reviewAppointmentDialog/reviewAppointmentAngry/ReviewAppointmentAngry';
import { ReviewAppointmentSocial } from 'app/appointmentManagement/finishedAppointmentManagementPanel/reviewAppointmentDialog/reviewAppointmentSocial/ReviewAppointmentSocial';
import { useReviewAppointment } from 'hooks/useReviewAppointment/useReviewAppointment';

import { ReviewAppointmentFaceProps } from './ReviewAppointmentFace.types';
import { useStyles } from './ReviewAppointmentFace.styles';

const values = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const ReviewAppointmentFace = memo(({ appointment }: ReviewAppointmentFaceProps) => {
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const setDialog = useDialogDispatch();
  const reviewAppointment = useReviewAppointment();
  const [value, setValue] = useState<number>(-1);

  const handleReviewClick = async () => {
    let faceType = null;
    if (value !== -1) {
      await reviewAppointment(value, '', '', appointment.id);
      if (value < 7) {
        faceType = <ReviewAppointmentAngry appointment={appointment} reviewValue={value} />;
      } else if (value > 6 && value < 9) {
        faceType = <ReviewAppointmentNeutral appointment={appointment} reviewValue={value} />;
      } else {
        faceType = <ReviewAppointmentSocial appointment={appointment} reviewValue={value} />;
      }

      setDialog({
        open: true,
        variant: 'schedule',
        dialogContent: faceType,
      });
    }
  };

  return (
    <div className={styles.faceContainer}>
      {isMobile ? (
        <>
          <div>
            <div className={styles.numPoint}>
              {values.slice(0, 5).map(item => (
                <div
                  onClick={() => setValue(item)}
                  style={{ background: value === item ? '#026937' : '#FFFFFF' }}
                  className={styles.numBox}
                >
                  <Typography variant="h3" style={{ color: value === item ? '#FFFFFF' : '#000000' }}>
                    {item}
                  </Typography>
                </div>
              ))}
            </div>

            <div style={{ marginTop: '0.7rem' }} className={styles.numPoint}>
              {values.slice(5).map(item => (
                <div
                  onClick={() => setValue(item)}
                  style={{ background: value === item ? '#026937' : '#FFFFFF' }}
                  className={styles.numBox}
                >
                  <Typography variant="h3" style={{ color: value === item ? '#FFFFFF' : '#000000' }}>
                    {item}
                  </Typography>
                </div>
              ))}
            </div>
          </div>

          <div className={styles.numEndText}>
            <div className={styles.notLikely}>0-NOT LIKELY</div>
            <div className={styles.veryLikely}>10-VERY LIKELY</div>
          </div>

          <div className={styles.submitDiv}>
            <Button buttonType="twoTone" fullWidth onClick={handleReviewClick}>
              SUBMIT
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className={styles.numPoint}>
            {values.map(item => (
              <div
                onClick={() => setValue(item)}
                style={{ background: value === item ? '#026937' : '#FFFFFF' }}
                className={styles.numBox}
              >
                <Typography variant="h3" style={{ color: value === item ? '#FFFFFF' : '#000000' }}>
                  {item}
                </Typography>
              </div>
            ))}
          </div>
          <div className={styles.numEndText}>
            <div className={styles.notLikely}>NOT LIKELY</div>
            <div className={styles.veryLikely}>VERY LIKELY</div>
          </div>

          <div className={styles.submitDiv}>
            <Button buttonType="twoTone" fullWidth onClick={handleReviewClick}>
              SUBMIT
            </Button>
          </div>
        </>
      )}
    </div>
  );
});
