import React from 'react';
import { Typography } from '@material-ui/core';

import { ActionButtonProps } from './ActionButton.types';
import { useStyles } from './ActionButton.styles';

export const ActionButton: React.FC<ActionButtonProps> = ({ icon, text, onClick }) => {
  const styles = useStyles();

  return (
    <div onClick={onClick} className={styles.buttonPanel}>
      <div className={styles.icon}>{icon}</div>

      <Typography variant="h5" className={styles.text}>
        {text}
      </Typography>
    </div>
  );
};
