import { memo, useEffect } from 'react';
import { useAlert } from '@chhjit/react-components';

import { useMessagesState } from 'hooks/useMessages/useMessages';
import { ScrollToTop } from 'ui/scrollToTop/ScrollToTop';

export const Message = memo(() => {
  const { showAlert } = useAlert();

  const { message, type } = useMessagesState();

  useEffect(() => {
    if (message) {
      showAlert(message, {
        variant: type,
        autoHideDuration: null,
      });
    }
  }, [message, showAlert, type]);

  return <>{message && <ScrollToTop />}</>;
});
