import { colors } from '@chhjit/react-components';
import { makeStyles } from '@material-ui/core';

export const useNodeBadgeStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    padding: 16,
    border: `1px solid ${colors.grey30}`,
    backgroundColor: colors.grey20,
    borderRadius: 4,
    marginTop: 24,
    marginBottom: 24,
  },
}));
