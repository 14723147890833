import { memo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Button, useDialogDispatch } from '@chhjit/react-components';

import { PaymentForm } from 'ui/paymentForm/PaymentForm';
import { useStyles } from '../AppointmentDetails.styles';
import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { useAppointmentsDispatch } from 'hooks/useAppointments/useAppointments';
import { SET_RELOAD_APPOINTMENTS } from 'context/appointments/appointmentsContextReducer/AppointmentsContextReducer.types';
import { useMessagesDispatch } from 'hooks/useMessages/useMessages';
import { usePayment } from 'hooks/usePayment/usePayment';

export const AppointmentPayment = memo(({ appointment }: { appointment: Appointment }) => {
  const styles = useStyles();

  const { isHunkPay } = usePayment();

  const appointmentsDispatch = useAppointmentsDispatch();
  const setMessage = useMessagesDispatch();
  const setDialog = useDialogDispatch();

  const handlePaymentSuccess = () => {
    setDialog({ open: false });
    appointmentsDispatch({ type: SET_RELOAD_APPOINTMENTS });
    setMessage({ message: 'Payment successful!', type: 'success' });
  };

  const handlePaymentFailed = () => {
    setMessage({ message: 'Payment failed!', type: 'error' });
  };

  const handleOpenDialog = () => {
    setDialog({
      open: true,
      variant: 'schedule',
      dialogContent: (
        <div style={{ maxWidth: isHunkPay ? 700 : 446, paddingRight: 0.5 }}>
          <div style={{ maxWidth: '100%', width: 'calc(100vw - 124px)' }}>
            <PaymentForm
              appointment={appointment}
              paymentAmount={appointment.balanceDue}
              tipEnabled={true}
              onSuccess={handlePaymentSuccess}
              onFailed={handlePaymentFailed}
            />
          </div>
        </div>
      ),
    });
  };

  return (
    <Grid container justify="space-between" spacing={2}>
      <Grid item xs={12} sm={12} md>
        <Typography variant="body2" className={styles.detailsTitle}>
          Balance due
        </Typography>
        <Typography variant="body1" color="textSecondary">
          ${appointment.balanceDue}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md="auto">
        <Button buttonType="filled" onClick={() => handleOpenDialog()}>
          Pay Now
        </Button>
      </Grid>
    </Grid>
  );
});
