import React from 'react';

import { Header } from 'app/header/Header';
import { AppRoutes } from 'routing/AppRoutes';

import { useStyles } from './Page.styles';

export function Page() {
  const styles = useStyles();
  return (
    <>
      <Header />

      <div className={styles.page}>
        <AppRoutes />
      </div>
    </>
  );
}
