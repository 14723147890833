import { memo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { CloseIcon, colors } from '@chhjit/react-components';

import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { AppRoute } from 'routing/AppRoute.enum';
import { CancelAppointmentProps } from 'app/cancelAppointment/CancelAppointment.types';
import { ActionButton } from 'ui/actionButton/ActionButton';
import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';

export const CancelAppointmentButton = memo(({ appointment }: { appointment: Appointment }) => {
  const { toggles } = useFeatureFlags();

  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: toggles.Payments_AllowPrepaymentRefunds ? AppRoute.preCancel : AppRoute.cancel,
          state: { appointment } as CancelAppointmentProps,
        }}
        push={false}
      />
    );
  }

  return (
    <ActionButton
      icon={<CloseIcon color={colors.primary.main} />}
      text="Cancel Appointment"
      onClick={() => setRedirect(true)}
    />
  );
});
