/* eslint-disable react/jsx-props-no-spreading */
import { Grid, Typography, MenuItem, FormControl, FormHelperText } from '@material-ui/core';
import { memo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Select, TextArea, useDialogDispatch } from '@chhjit/react-components';

import { ReviewFormData } from '../ReviewAppointmentDialog.types';
import { useReviewAppointment } from 'hooks/useReviewAppointment/useReviewAppointment';
import { ReviewAppointmentThankYou } from '../reviewAppointmentThankYou/ReviewAppointmentThankYou';

import { ReviewAppointmentAngryProps } from './ReviewAppointmentAngry.types';
import { useStyles } from './ReviewAppointmentAngry.styles';

export const ReviewAppointmentAngry = memo(({ appointment, reviewValue }: ReviewAppointmentAngryProps) => {
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<ReviewFormData>();
  const reviewAppointment = useReviewAppointment();
  const setDialog = useDialogDispatch();

  const submit = async (values: ReviewFormData) => {
    setIsLoading(true);
    await reviewAppointment(reviewValue, values.message, digestReason(values.subject), appointment.id);
    setDialog({
      open: true,
      variant: 'schedule',
      dialogContent: <ReviewAppointmentThankYou appointment={appointment} reviewType={'un-satisfied'} />,
    });
  };

  const digestReason = (reason: string) => {
    switch (reason) {
      case 'Damage To My Item(s)':
        return 'Property Damage';
      case 'Damage(s) To My Property':
        return 'Property Damage';
      case 'I Am Missing Item(s)':
        return 'Missing Property';
      case 'Incorrect Amount Charged To My Card':
        return 'Incorrect Amount Charged';
      case '-1':
        return '';
      default:
        return 'Other';
    }
  };

  const problemReasons = [
    'Job Is Incomplete',
    'Damage To My Item(s)',
    'Damage(s) To My Property',
    'I Am Missing Item(s)',
    'Incorrect Amount Charged To My Card',
    'Other',
  ];

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container direction="row" alignItems="center" spacing={2} className={styles.dialogContainer}>
        <Grid item xs={12}>
          <Typography variant="h4" color="secondary">
            We are sorry that you are not satisfied with your service.
            <br />
            <span className={styles.subtitle}>Let's make things right.</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={styles.feedbackInput} error={!!errors.subject?.message}>
            <Controller
              name="subject"
              control={control}
              defaultValue="0"
              rules={{
                validate: value => (!value || value === '0' ? 'Please select what went wrong' : true),
              }}
              render={({ field, fieldState }) => (
                <Select {...field} fullWidth variant={'outlined'} size="large" error={!!fieldState.error}>
                  <MenuItem value="0" disabled>
                    <span className="select-placeholder">What went wrong?</span>
                  </MenuItem>
                  {problemReasons.map((reason, index) => (
                    <MenuItem value={reason} key={index}>
                      {reason}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            <div>
              <FormHelperText>{errors.subject && errors.subject.message}</FormHelperText>
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={styles.feedbackInput} error={!!errors.message?.message}>
            <TextArea
              {...register('message', {
                required: 'This field is required',
                minLength: {
                  value: 5,
                  message: 'This field should have at least 5 characters',
                },
              })}
              size={'large'}
              variant={'outlined'}
              fullWidth
              placeholder={'Description'}
              rows={5}
            />

            <div>
              <FormHelperText>{errors.message && errors.message.message}</FormHelperText>
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button buttonType="twoTone" type="submit" isLoading={isLoading} fullWidth>
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
});
