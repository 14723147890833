import { memo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Scrollbars } from '@chhjit/react-components';

import { TimeSlotButton } from '../time-slot-button';

import { useAvailibilityColumnStyles } from './styles';
import { AvailabilityColumnProps } from './types';
import { AvailabilityColumnSkeleton } from './skeleton';

export const AvailabilityColumn = memo(
  ({ isSelected, label, list, loading, onSelect, disabled, getArrivalWindow }: AvailabilityColumnProps) => {
    const styles = useAvailibilityColumnStyles();

    return (
      <div style={{ width: '100%' }}>
        <div className={styles.hourLabel}>
          <Typography align="center" color="secondary" variant="subtitle2">
            {label.toUpperCase()}
          </Typography>
        </div>

        {!loading && !list.length && (
          <div className={styles.noHours}>
            <Typography align="center" color="inherit">
              No Availability
            </Typography>
          </div>
        )}

        {!loading && !!list.length && (
          <Scrollbars autoHide autoHeight autoHeightMax={326} autoHeightMin={326}>
            <div className={styles.column}>
              {list.map((item, index) => (
                <TimeSlotButton
                  key={index}
                  timeSlot={item}
                  onSelect={onSelect}
                  isSelected={isSelected(item)}
                  duration={getArrivalWindow(item)}
                  disabled={disabled}
                />
              ))}
            </div>
          </Scrollbars>
        )}

        {loading && <AvailabilityColumnSkeleton />}
      </div>
    );
  },
);
