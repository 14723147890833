import { SvgIcon } from '@material-ui/core';
import { SvgIconProps, colors } from '@chhjit/react-components';
import { memo } from 'react';

export const TruckLoadedIcon = memo(({ className, fontSize = 48 }: SvgIconProps) => (
  <SvgIcon
    className={className}
    width="48"
    height="49"
    viewBox="0 0 48 49"
    style={{ fill: 'none', fontSize }}
    fill="currentColor"
  >
    <g clip-path="url(#clip0_2702_138)">
      <path
        d="M38.9775 15.625H31.955V21.6966C31.955 19.4169 30.4085 17.5725 28.4954 17.5725H25.0357H10.3262C9.35249 17.5725 8.4131 18.088 7.78302 18.9816L4.15149 24.1024V32.4767C4.15149 34.7564 5.69804 36.6008 7.61118 36.6008H9.34103H9.42122C9.77635 38.6285 11.5291 40.1636 13.6714 40.1636C15.8136 40.1636 17.5664 38.64 17.9215 36.6008H18.0934H28.5755H28.6557C29.0109 38.6285 30.7636 40.1636 32.9059 40.1636C35.0482 40.1636 36.8009 38.64 37.156 36.6008H43.9723C45.0263 36.6008 45.9886 35.7302 45.9886 34.5846V22.6475C46 18.7983 42.9184 15.625 38.9775 15.625Z"
        fill={colors.primary.light}
      />
      <path
        d="M25.7574 15.8197H16.501C16.1458 15.8197 15.848 15.5562 15.7907 15.2125L15.5616 13.7118C15.5387 13.5514 15.5731 13.3795 15.6532 13.2421L18.1965 8.84298C18.3912 8.49931 18.8265 8.38475 19.1702 8.5795L27.0404 13.0931C27.2123 13.1962 27.3383 13.3681 27.3841 13.5628C27.4299 13.7576 27.3841 13.9638 27.2581 14.1356L26.5478 15.1094C26.5249 15.2469 26.4562 15.3729 26.3645 15.4645C26.2385 15.6822 26.0094 15.8197 25.7574 15.8197ZM17.1196 14.3877H25.3106L25.6428 13.9409L19.09 10.1833L17.028 13.7461L17.1196 14.3877Z"
        fill={colors.secondary.main}
      />
      <path d="M16.2719 13.5971L15.573 9.20947L5.97296 10.5956L6.55721 15.0978H15.4585L16.2719 13.5971Z" fill="white" />
      <path
        d="M15.4585 15.8196H6.55722C6.19063 15.8196 5.89277 15.5561 5.84695 15.1895L5.27415 10.6759C5.22833 10.2864 5.49181 9.93124 5.88132 9.87397L15.4814 8.4878C15.8594 8.43052 16.226 8.694 16.2948 9.08351L16.9936 13.4826C17.0165 13.643 16.9936 13.7919 16.9134 13.9294L16.1 15.4301C15.9625 15.6707 15.722 15.8196 15.4585 15.8196ZM7.18729 14.3876H15.0346L15.5272 13.4711L14.9773 10.0114L6.77488 11.2029L7.18729 14.3876Z"
        fill={colors.secondary.main}
      />
      <path
        d="M7.06129 34.848H5.33144C3.0288 34.848 1.15002 32.6714 1.15002 30.0021V21.6278C1.15002 21.4789 1.19585 21.33 1.2875 21.2154L4.91903 16.0946C5.68658 15.0063 6.83217 14.3877 8.0465 14.3877H26.2156C28.5183 14.3877 30.397 16.5643 30.397 19.2336V27.9401C30.397 28.341 30.0763 28.6503 29.6868 28.6503C29.2973 28.6503 28.9765 28.3296 28.9765 27.9401V19.2336C28.9765 17.3548 27.7393 15.8197 26.2271 15.8197H8.03504C7.29041 15.8197 6.58014 16.2206 6.07608 16.9195L2.58202 21.857V30.0021C2.58202 31.8809 3.81926 33.416 5.33144 33.416H7.06129C7.45079 33.416 7.77156 33.7368 7.77156 34.1263C7.77156 34.5158 7.45079 34.848 7.06129 34.848Z"
        fill={colors.primary.main}
      />
      <path
        d="M41.6926 34.8478H34.8649C34.4754 34.8478 34.1546 34.527 34.1546 34.1375C34.1546 33.748 34.4754 33.4272 34.8649 33.4272H41.6926C42.3227 33.4272 42.9986 32.9002 42.9986 32.1212V23.3804H29.6639C29.2744 23.3804 28.9536 23.0596 28.9536 22.6701V13.1617C28.9536 12.7607 29.2744 12.4399 29.6639 12.4399H36.6864C40.948 12.4399 44.4191 15.9111 44.4191 20.1727V32.0983C44.4306 33.622 43.2048 34.8478 41.6926 34.8478ZM30.3856 21.9369H42.9986V20.1727C42.9986 16.6901 40.169 13.8719 36.6978 13.8719H30.3971V21.9369H30.3856Z"
        fill={colors.primary.main}
      />
      <path
        d="M30.6262 38.4108C27.8424 38.4108 25.5856 36.1425 25.5856 33.3702C25.5856 30.5979 27.8538 28.3296 30.6262 28.3296C33.41 28.3296 35.6668 30.5979 35.6668 33.3702C35.6668 36.1425 33.41 38.4108 30.6262 38.4108ZM30.6262 29.7501C28.5985 29.7501 27.0176 31.3425 27.0176 33.3587C27.0176 35.375 28.5985 36.9674 30.6262 36.9674C32.6539 36.9674 34.2348 35.375 34.2348 33.3587C34.2348 31.3425 32.6539 29.7501 30.6262 29.7501Z"
        fill={colors.primary.main}
      />
      <path
        d="M11.3916 38.4108C8.60783 38.4108 6.35101 36.1425 6.35101 33.3702C6.35101 30.5979 8.61929 28.3296 11.3916 28.3296C14.1754 28.3296 16.4322 30.5979 16.4322 33.3702C16.4322 36.1425 14.1754 38.4108 11.3916 38.4108ZM11.3916 29.7501C9.36392 29.7501 7.78301 31.3425 7.78301 33.3587C7.78301 35.375 9.36392 36.9674 11.3916 36.9674C13.4193 36.9674 15.0002 35.375 15.0002 33.3587C15.0002 31.3425 13.4193 29.7501 11.3916 29.7501Z"
        fill={colors.primary.main}
      />
      <path
        d="M26.2958 34.8478H15.8136C15.4241 34.8478 15.1033 34.527 15.1033 34.1375C15.1033 33.748 15.4241 33.4272 15.8136 33.4272H26.2958C26.6853 33.4272 27.0061 33.748 27.0061 34.1375C27.0061 34.527 26.6967 34.8478 26.2958 34.8478Z"
        fill={colors.primary.main}
      />
      <path
        d="M23.764 26.1758C23.3745 26.1758 23.0538 25.855 23.0538 25.4655V19.4168C23.0538 19.0159 23.3745 18.7065 23.764 18.7065C24.1535 18.7065 24.4743 19.0273 24.4743 19.4168V25.4655C24.4858 25.855 24.165 26.1758 23.764 26.1758Z"
        fill={colors.secondary.main}
      />
      <path
        d="M17.4404 26.1758C17.0509 26.1758 16.7301 25.855 16.7301 25.4655V19.4168C16.7301 19.0159 17.0509 18.7065 17.4404 18.7065C17.8299 18.7065 18.1506 19.0273 18.1506 19.4168V25.4655C18.1621 25.855 17.8413 26.1758 17.4404 26.1758Z"
        fill={colors.secondary.main}
      />
      <path
        d="M11.1282 26.1758C10.7387 26.1758 10.4179 25.855 10.4179 25.4655V19.4168C10.4179 19.0159 10.7387 18.7065 11.1282 18.7065C11.5177 18.7065 11.8384 19.0273 11.8384 19.4168V25.4655C11.8384 25.855 11.5177 26.1758 11.1282 26.1758Z"
        fill={colors.secondary.main}
      />
    </g>
    <defs>
      <clipPath id="clip0_2702_138">
        <rect width="44.85" height="31.6642" fill="white" transform="translate(1.15002 8.48779)" />
      </clipPath>
    </defs>
  </SvgIcon>
));
