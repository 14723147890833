import { useMemo } from 'react';
import { useSplitTreatments } from '@chhjit/react-components';

const flagNames = ['Payments_AllowPrepaymentRefunds'] as const;

type Key = typeof flagNames[number];

type Toggles = {
  [key in Key]: boolean;
};

export const useFeatureFlags = (): {
  toggles: Toggles;
  treatments: SplitIO.TreatmentsWithConfig;
} => {
  const { treatments } = useSplitTreatments({
    names: Array.from(flagNames),
  });

  const toggles = useMemo(
    () => flagNames.reduce((keys, key) => ({ ...keys, [key]: treatments[key].treatment === 'on' }), {} as Toggles),
    [treatments],
  );

  return { toggles, treatments };
};
