export enum AppointmentCategory {
  'junk' = 1,
  'labor' = 4,
  'move' = 5,
  'moveLabor' = 7,
  'expressJunk' = 8,
}

export type AppointmentCategoriesKeys = keyof typeof AppointmentCategory;
export type AppointmentCategorySpec = {
  [key in AppointmentCategoriesKeys]: {
    displayName: string;
    id: number;
  };
};

export const getAppointmentCategories = (): AppointmentCategorySpec => ({
  junk: { id: AppointmentCategory.junk, displayName: 'Junk Removal' },
  labor: { id: AppointmentCategory.labor, displayName: 'Labor' },
  move: { id: AppointmentCategory.move, displayName: 'Moving' },
  moveLabor: { id: AppointmentCategory.moveLabor, displayName: 'Move labor' },
  expressJunk: {
    id: AppointmentCategory.expressJunk,
    displayName: 'Express Junk',
  },
});

export enum AppointmentType {
  'JOB' = 'Job',
  'EST' = 'Estimate',
  'LJOB' = 'Linked Job',
}

export type TaxRate = {
  id: number;
  name: string;
  rate: number;
};

export type ReviewData = {
  sentiment: number;
  message: string;
  subject: string;
};

export type ReviewSettings = {
  id: number;
  url: string;
  order: number;
  active: boolean;
  allow_text?: boolean;
  platform: {
    id: number;
    name: string;
    color: string;
    logo: {
      url: string;
    };
  };
};

export type Payment = {
  id: number;
  account_id: number;
  location_id: number;
  appointment_id: number;
  payment: {
    amount: number;
    auth_code: string;
    batch: string;
    check_number: string;
    credit_card: {
      address: string;
      name: string;
      number: string;
      type_id: string;
      zip: string;
      expiry_month: string;
      expiry_year: string;
      expiry: string;
    };
    created_date: string;
    date: string;
    is_refunded: boolean;
    memo: string;
    transaction_id: string;
    method: {
      id: number;
      name: string;
    };
    created_by: string;
  };
};
