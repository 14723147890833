import { SvgIcon } from '@material-ui/core';
import { memo } from 'react';

export const MoneyBagIcon = memo(({ className, fontSize }: { className?: string; fontSize?: number }) => (
  <SvgIcon
    className={className}
    width="18"
    height="22"
    viewBox="0 0 18 22"
    fill="none"
    style={{ fill: 'none', fontSize }}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.00201 10.4199C9.37153 10.4199 9.67109 10.7195 9.67109 11.089V11.1336H10.3402C10.7097 11.1336 11.0093 11.4332 11.0093 11.8027C11.0093 12.1722 10.7097 12.4718 10.3402 12.4718H8.46676C8.38132 12.4718 8.26199 12.5106 8.1506 12.6044C8.03954 12.698 7.98129 12.8082 7.96699 12.894C7.91613 13.1992 8.09245 13.4408 8.23229 13.4875C8.24122 13.4904 8.25009 13.4936 8.25889 13.497L10.1208 14.2062C11.0403 14.5223 11.3947 15.5229 11.2678 16.2841C11.1929 16.7335 10.9389 17.1139 10.6262 17.3773C10.3662 17.5962 10.0344 17.7605 9.67109 17.8095V17.9137C9.67109 18.2832 9.37153 18.5828 9.00201 18.5828C8.63248 18.5828 8.33292 18.2832 8.33292 17.9137V17.8245H7.66386C7.29433 17.8245 6.99477 17.5249 6.99477 17.1554C6.99477 16.7859 7.29433 16.4863 7.66386 16.4863H9.44809C9.53353 16.4863 9.65286 16.4475 9.76425 16.3537C9.87531 16.2602 9.93355 16.1499 9.94786 16.0641C9.99872 15.7589 9.8224 15.5173 9.68256 15.4707C9.67363 15.4677 9.66476 15.4645 9.65595 15.4612L7.79409 14.7519C6.87455 14.4359 6.52016 13.4352 6.64703 12.674C6.72194 12.2246 6.97593 11.8442 7.28863 11.5809C7.56976 11.3441 7.93479 11.1712 8.33292 11.139V11.089C8.33292 10.7195 8.63248 10.4199 9.00201 10.4199Z"
      fill="#EA7200"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.60455 7.69936C3.60455 7.32983 3.90411 7.03027 4.27364 7.03027H13.6409C14.0104 7.03027 14.3099 7.32983 14.3099 7.69936C14.3099 8.06889 14.0104 8.36845 13.6409 8.36845H4.27364C3.90411 8.36845 3.60455 8.06889 3.60455 7.69936Z"
      fill="#EA7200"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.15279 0.634611C8.24809 0.525693 8.51046 0.25 8.91281 0.25C9.0991 0.25 9.30295 0.293379 9.48564 0.374576C9.65241 0.448694 9.88426 0.591989 10.0277 0.851641L10.4437 1.51729C10.4622 1.53999 10.4783 1.55614 10.4913 1.5675C10.4941 1.5661 10.497 1.56473 10.4998 1.56337L12.1586 0.77763C12.7318 0.431029 13.4087 0.632608 13.7573 0.981241C13.7885 1.0124 13.8165 1.04655 13.8409 1.08321C14.16 1.56182 14.1462 2.05481 14.0081 2.46885C13.9991 2.49594 13.9884 2.52243 13.9759 2.54815L12.727 5.13528C12.5663 5.46806 12.1663 5.6076 11.8335 5.44695C11.5008 5.28629 11.3612 4.88629 11.5219 4.55351L12.749 2.01157C12.7528 1.99787 12.7556 1.98558 12.7576 1.97459L11.1095 2.75528C10.455 3.15347 9.74562 2.81754 9.35887 2.30187C9.34751 2.28672 9.33679 2.27109 9.32675 2.25503L9.0125 1.75223C9.00068 1.7689 8.98809 1.78504 8.97476 1.80058L8.46145 2.39944C8.07024 2.94489 7.3943 3.03567 6.87709 2.90637C6.82973 2.89453 6.78381 2.87754 6.74014 2.85571L5.14545 2.05836L6.38885 4.54517C6.55411 4.87569 6.42014 5.27759 6.08963 5.44285C5.75911 5.60811 5.35721 5.47414 5.19195 5.14362L3.9583 2.67632C3.60564 2.10159 3.80745 1.42054 4.15754 1.07045C4.18869 1.0393 4.22285 1.01129 4.25951 0.986854C4.42673 0.875375 4.653 0.750013 4.92211 0.705162C5.2035 0.658264 5.49491 0.705852 5.76374 0.871385L7.25855 1.61879C7.30935 1.62494 7.34699 1.62007 7.36852 1.61392C7.37414 1.61231 7.37808 1.61086 7.38068 1.60976C7.39396 1.5905 7.40825 1.57195 7.42347 1.55419L7.90997 0.986613C7.96945 0.878609 8.05123 0.750682 8.15279 0.634611ZM9.16752 1.50763C9.16752 1.50764 9.16722 1.50794 9.16662 1.50849L9.16752 1.50763Z"
      fill="#EA7200"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.0621 9.23976C5.34452 9.47805 5.3803 9.90018 5.142 10.1826L2.74217 13.0269C1.66235 14.4232 1.50728 16.2822 2.28509 17.8378C3.06509 19.3978 4.70834 20.4119 6.41485 20.4119H11.5891C13.3785 20.4119 14.936 19.4036 15.7189 17.8378C16.4959 16.2839 16.342 14.4273 15.2654 13.0315L12.7806 10.1917C12.5373 9.91363 12.5655 9.49093 12.8436 9.24759C13.1216 9.00426 13.5444 9.03244 13.7877 9.31054L16.2856 12.1653C16.2941 12.175 16.3023 12.185 16.3102 12.1951C17.7212 14.0092 17.9203 16.4272 16.9158 18.4362C15.9145 20.4389 13.9035 21.7501 11.5891 21.7501H6.41485C4.19605 21.7501 2.09243 20.4447 1.08819 18.4362C0.0836573 16.4272 0.282782 14.0092 1.69376 12.1951C1.69921 12.1881 1.70481 12.1812 1.71053 12.1744L4.11924 9.31966C4.35754 9.03723 4.77967 9.00146 5.0621 9.23976Z"
      fill="#EA7200"
    />
  </SvgIcon>
));
