/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Collapse, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { memo, useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { Redirect, useHistory } from 'react-router-dom';
import { Button, Tooltip, useAlert } from '@chhjit/react-components';
import * as Square from '@square/web-sdk';

import { PageContent } from 'ui/page-content/PageContent';
import { useQuotesState } from 'hooks/useQuotes/useQuotes';
import { routePath } from 'utils/routePath/routePath';
import { AppRoute } from 'routing/AppRoute.enum';
import { usePayment } from 'hooks/usePayment/usePayment';
import { ProcessSpinner } from 'ui/process-spinner';
import { BillingInformationCard } from 'ui/billingInformationCard/BillingInformationCard';
import { useAddressOptions } from 'hooks/useAddressOptions/useAddressOptions';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useCreateAppointment } from 'hooks/useCreateAppointment/useCreateAppointment';
import { useAccountDataForm } from 'hooks/useAccountDataForm/useAccountDataForm';
import { useHunkPayForm } from 'hooks/useHunkPayForm/useHunkPayForm';
import { useSquareForm } from 'hooks/useSquareForm/useSquareForm';
import { BasicUserAccount } from 'api/types/account';
import { parseAddress } from 'utils/address/parseAddress';
import { accountDataErrorLabels } from 'hooks/useAccountDataForm/AccountDataForm.utils';
import { hunkPayErrorLabels } from 'hooks/useHunkPayForm/HunkPayForm.utils';

import { useStyles } from './PrePayment.styles';
import { PaymentProviderEnum } from './PrePayment.types';

export const PrePayment = memo(() => {
  const { showAlert } = useAlert();

  const history = useHistory();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'), {
    noSsr: true,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  const { addressOptions } = useAddressOptions();
  const { isLoading: appLoading, availability, prePayment, quote, isLoading, quoteLog } = useQuotesState();
  const { user } = useAuthState();
  const { makeAppointment } = useCreateAppointment();
  const { paymentProvider, isHunkPay, doSquarePayment, doHunkPayPayment } = usePayment();

  const styles = useStyles({ isHunkPay });

  const [showAccountDataForm, setShowAccountDataForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePaymentSuccess = useCallback(async () => {
    if (quote?.id) {
      history.replace(routePath.quoteDone(quote.id));
    }
  }, [history, quote?.id]);

  const handlePaymentFailed = useCallback(() => {
    showAlert('Payment failed!', {
      variant: 'error',
      autoHideDuration: null,
    });
    setLoading(false);
  }, [showAlert]);

  const billingInformationItems = useMemo(() => {
    const name = [user?.first_name, user?.last_name].filter(item => item).join(' ');
    const address = [user?.billing_address.address, user?.billing_address.address2].filter(item => item).join(' ');
    const state =
      addressOptions
        .find(addressOption => addressOption.countryValue === user?.billing_address.country)
        ?.states.find(state => state.value === user?.billing_address.state)?.name ?? user?.billing_address.state;
    const cityStatePostal = `${user?.billing_address.city ? `${user?.billing_address.city}, ` : ''}${
      state ? `${state} ` : ''
    }${user?.billing_address.postal}`;

    return [name, address, cityStatePostal];
  }, [
    user?.first_name,
    user?.last_name,
    user?.billing_address.address,
    user?.billing_address.address2,
    user?.billing_address.state,
    user?.billing_address.city,
    user?.billing_address.postal,
    user?.billing_address.country,
    addressOptions,
  ]);

  const accountDataDefaultValues = useMemo(
    () => ({
      firstName: user?.first_name,
      lastName: user?.last_name,
      address: user?.billing_address.address,
      address2: user?.billing_address.address2,
      city: user?.billing_address.city,
      state: user?.billing_address.state,
      postal: user?.billing_address.postal,
      country: user?.billing_address.country,
    }),
    [
      user?.billing_address.address,
      user?.billing_address.address2,
      user?.billing_address.city,
      user?.billing_address.country,
      user?.billing_address.postal,
      user?.billing_address.state,
      user?.first_name,
      user?.last_name,
    ],
  );

  const {
    isValid: isAccountDataFormValid,
    errors: accountDataErrors,
    renderAccountDataForm,
    getValues: getAccountDataValues,
  } = useAccountDataForm({
    defaultValues: accountDataDefaultValues,
    addressOptions,
  });

  const onSubmitSquareForm = useCallback(
    async (token: Square.TokenResult) => {
      if (token.status === 'OK' && token.token && prePayment?.amount) {
        setLoading(true);

        const response = await makeAppointment();

        if (response?.id) {
          const { success, error } = await doSquarePayment(
            token.token,
            prePayment?.amount,
            response.id,
            prePayment.square_location_id as string,
          );

          if (success && !error) {
            handlePaymentSuccess();
          } else {
            handlePaymentFailed();
          }
        }
      }
    },
    [
      prePayment?.amount,
      prePayment?.square_location_id,
      makeAppointment,
      doSquarePayment,
      handlePaymentSuccess,
      handlePaymentFailed,
    ],
  );

  const { renderSquarePaymentForm } = useSquareForm({
    paymentAmount: prePayment?.amount as number,
    onSubmitSquareForm,
    squareLocationId: prePayment?.square_location_id as string,
    user: { ...user, ...user?.billing_address } as BasicUserAccount,
  });

  const {
    renderHunkPayForm,
    encryptedCardDetails: hunkPayEncryptedCardDetails,
    isValid: hunkPayIsValid,
    errors: hunkPayErrors,
  } = useHunkPayForm({ shouldRender: isHunkPay });

  const onSubmitHunkPayForm = useCallback(async () => {
    setLoading(true);

    const accountData = getAccountDataValues();
    const { houseNumber, street } = parseAddress(accountData.address);

    const response = await makeAppointment();

    if (response?.id) {
      const { success, error } = await doHunkPayPayment(
        response.id,
        hunkPayEncryptedCardDetails,
        prePayment?.amount || 0,
        {
          first_name: accountData.firstName,
          last_name: accountData.lastName,
          phone: response.account.phone || ' ',
          email: response.account.email ?? '',
          address: {
            house_number: houseNumber || ' ',
            street: street || ' ',
            city: accountData.city,
            state: accountData.state,
            postal: accountData.postal,
            country: accountData.country,
          },
        },
      );

      if (success && !error) {
        handlePaymentSuccess();
      } else {
        handlePaymentFailed();
      }
    }
  }, [
    getAccountDataValues,
    makeAppointment,
    doHunkPayPayment,
    hunkPayEncryptedCardDetails,
    prePayment?.amount,
    handlePaymentSuccess,
    handlePaymentFailed,
  ]);

  const submitDisableReasons = useMemo(() => {
    if (!isHunkPay) {
      return '';
    }

    if (!isAccountDataFormValid) {
      const accountDataErrorsKeys = Object.keys(accountDataErrors);

      const errorsList = accountDataErrorsKeys
        .filter(key => accountDataErrorLabels.some(label => label.field === key))
        .map(key => accountDataErrorLabels.find(label => label.field === key))
        .sort((a, b) => (a?.index ?? 0) - (b?.index ?? 0))
        .map(label => label?.label ?? '');

      return (
        <Box p={1}>
          <Typography variant="subtitle1">
            Please review your billing address details
            {!!errorsList.length && (
              <ul className={styles.submitDisableReasonsList}>
                {errorsList.map(error => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            )}
          </Typography>
        </Box>
      );
    }

    if (!hunkPayIsValid) {
      // @ts-ignore
      const hunkPayErrorsKeys = Object.keys(hunkPayErrors).filter(key => !!hunkPayErrors[key]);

      const errorsList = hunkPayErrorsKeys
        .filter(key => hunkPayErrorLabels.some(label => label.field === key))
        .map(key => hunkPayErrorLabels.find(label => label.field === key))
        .sort((a, b) => (a?.index ?? 0) - (b?.index ?? 0))
        .map(label => label?.label ?? '');

      return (
        <Box p={1}>
          <Typography variant="subtitle1">
            Please review card details
            {!!errorsList.length && (
              <ul className={styles.submitDisableReasonsList}>
                {errorsList.map(error => (
                  <li key={error}>{error}</li>
                ))}
              </ul>
            )}
          </Typography>
        </Box>
      );
    }

    return '';
  }, [
    isHunkPay,
    isAccountDataFormValid,
    hunkPayIsValid,
    accountDataErrors.firstName,
    accountDataErrors.lastName,
    accountDataErrors.address,
    accountDataErrors.city,
    accountDataErrors.state,
    accountDataErrors.postal,
    accountDataErrors.country,
    hunkPayErrors.cardNumber,
    hunkPayErrors.expirationDate,
    hunkPayErrors.cvv,
    styles.submitDisableReasonsList,
  ]);

  const onCollectPayment = useCallback(async () => {
    if (paymentProvider === PaymentProviderEnum.Square) {
      const btn = document.getElementById('square-submit-button');

      if (btn) {
        btn.click();
      }
    } else {
      await onSubmitHunkPayForm();
    }
  }, [paymentProvider, onSubmitHunkPayForm]);

  if (!isLoading && !loading && !quote?.id) {
    showAlert("Appointment is not provided or doesn't exist", {
      variant: 'error',
      autoHideDuration: null,
    });
    return <Redirect to={AppRoute.dashboard} />;
  }

  if (!quote?.id) {
    return <></>;
  }

  if (!appLoading && !quoteLog?.signed) {
    showAlert('Please sign your estimate first.', {
      variant: 'error',
      autoHideDuration: null,
    });
    return <Redirect to={routePath.quoteEstimate(quote.id)} />;
  }

  if (!availability || !prePayment?.amount || !prePayment?.collect_online) {
    showAlert('Please select arrival window.', {
      variant: 'error',
      autoHideDuration: null,
    });
    return <Redirect to={routePath.quoteAvailability(quote.id)} />;
  }

  return (
    <div className={styles.root} data-testid="estimate">
      {loading && <ProcessSpinner />}

      <PageContent title={`Book your ${format(new Date(quote.date), 'MM/dd/yyyy')} move`} variant="secondary">
        <Box my={3}>
          <Box mb={3}>
            <Typography>
              A <b>prepayment amount of ${prePayment?.amount}</b> is required to book this service.{' '}
              {prePayment?.refundable ? (
                <>
                  A full refund will be possible until <b>{prePayment?.refundable_by}.</b>
                </>
              ) : (
                'This prepayment is not refundable.'
              )}
            </Typography>
          </Box>

          <div className={styles.paymentFormRoot}>
            {isHunkPay && (
              <div className={styles.accountDataFormContainer}>
                <Collapse in={!showAccountDataForm}>
                  <Typography variant="h4" className={styles.billingInformationTitle}>
                    Billing information:
                  </Typography>
                  <BillingInformationCard
                    infoItems={billingInformationItems}
                    action={{ title: 'Change', onClick: () => setShowAccountDataForm(true) }}
                    hasError={!isAccountDataFormValid}
                  />
                </Collapse>

                <Collapse in={showAccountDataForm}>{renderAccountDataForm()}</Collapse>
              </div>
            )}

            <div className={styles.paymentFormContainer}>
              {paymentProvider === PaymentProviderEnum.Square ? (
                <>{renderSquarePaymentForm()}</>
              ) : (
                <>{renderHunkPayForm()}</>
              )}
            </div>

            <Grid container alignItems="center" justify="center" spacing={isTablet ? 2 : 5}>
              <Grid item xs={12} sm={6} md={5} style={{ order: isMobile ? 2 : 1 }}>
                <Button
                  buttonType="outlined"
                  fullWidth
                  onClick={() => history.replace(routePath.quoteAvailability(quote.id))}
                >
                  Back
                </Button>
              </Grid>

              <Grid item xs={12} sm={6} md={5} style={{ order: isMobile ? 1 : 2 }}>
                <Tooltip
                  title={submitDisableReasons}
                  arrow
                  placement="top"
                  enterTouchDelay={0}
                  disableFocusListener={!submitDisableReasons}
                  disableHoverListener={!submitDisableReasons}
                  disableTouchListener={!submitDisableReasons}
                  zIndex={999999999}
                >
                  <div>
                    <Button
                      buttonType="twoTone"
                      fullWidth
                      disabled={isHunkPay && (!hunkPayIsValid || !isAccountDataFormValid)}
                      isLoading={loading}
                      onClick={onCollectPayment}
                    >
                      Process pre-payment
                    </Button>
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        </Box>
      </PageContent>
    </div>
  );
});
