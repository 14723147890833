import { useMutation, useParameterizedQuery } from 'react-fetching-library';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from '@chhjit/react-components';

import {
  convertEstimate,
  createAppointment,
  rescheduleAppointment,
} from 'api/actions/appointments/AppointmentsActions';
import {
  AppointmentResponseData,
  AppointmentsResponse,
  ConvertEstimateRequest,
  CreateAppointmentPayload,
  CreateAppointmentRequest,
  RescheduleAppointmentRequest,
} from 'api/actions/appointments/AppointmentsActions.types';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';
import { useQuotesDispatch, useQuotesState } from 'hooks/useQuotes/useQuotes';
import {
  SET_APPOINTMENT,
  SET_AVAILABILITY,
  SET_IS_BOOKED,
  SET_QUOTE,
} from 'context/quotes/quotesContextReducer/QuotesContextReducer.types';
import { updateQuote, updateQuoteLog } from 'api/actions/quotes/QuotesActions';
import { QuoteLogPutRequest, QuoteResponse, QuoteUpdateRequest } from 'api/actions/quotes/QuotesActions.types';
import { useArrivalWindows } from 'hooks/useArrivalWindows/useArrivalWindows';
import { routePath } from 'utils/routePath/routePath';

export const useCreateAppointment = () => {
  const throwError = useAsyncError();
  const history = useHistory();
  const { showAlert } = useAlert();

  const { accountId, locationId, token } = useAuthState();
  const { quote, quoteLog, availability, appointment } = useQuotesState();
  const setState = useQuotesDispatch();

  const { doCheckAvailability } = useArrivalWindows();

  // creating a job
  const { query: createAppointmentQuery, loading: createLoading } = useParameterizedQuery<AppointmentsResponse>(
    createAppointment,
  );
  const createJob = useCallback(async () => {
    if (accountId && locationId && token && quote && availability) {
      const payload: CreateAppointmentPayload = {
        type: 'JOB',
        category: quote.category,
        origin: quote.origin,
        rooms: quote.rooms,
        destination: quote.destination,
        appliances: quote.appliances,
        heavy_items: quote.heavy_items,
        assembly_items: quote.assembly_items,
        stops: quote.stops.map(stop => stop.id),
        start_date: availability.start,
        end_date: availability.end,
        resource: { id: availability.resourceId },
        create_schedule: true,
        account: {
          id: quote.account.id,
        },
      };

      const createAppointmentRequest: CreateAppointmentRequest = {
        path: { account_id: accountId, location_id: locationId },
        query: { token: token },
        payload,
      };

      const { error, payload: response } = await createAppointmentQuery(createAppointmentRequest);

      if (!error && response?.appointments[0]) {
        setState({
          type: SET_APPOINTMENT,
          payload: response.appointments[0],
        });

        return response.appointments[0];
      } else {
        throwError(`Error on: CreateAppointment, ${response?.meta?.status?.description}`);
      }
    }
  }, [accountId, availability, createAppointmentQuery, locationId, quote, setState, throwError, token]);

  // reschedule converted job
  const { query: rescheduleAppointmentQuery } = useParameterizedQuery<AppointmentsResponse>(rescheduleAppointment);
  const rescheduleJob = useCallback(
    async (appointmentId: number) => {
      if (availability && quote && token) {
        const request: RescheduleAppointmentRequest = {
          path: { location_id: quote.location.id, appointment_id: appointmentId, account_id: quote.account.id },
          query: { token },
          payload: {
            start_date: availability.start,
            end_date: availability.end,
            account: {
              id: quote.account.id,
            },
            resource: { id: availability.resourceId },
            reason: 'convert',
            notify_client: true,
          },
        };

        const { error, payload } = await rescheduleAppointmentQuery(request);

        if (!error && payload?.appointments) {
          return true;
        } else {
          throwError(`Error on: AvailabilityRequest, ${payload?.meta?.status?.description}`);
        }
      }
    },
    [availability, quote, rescheduleAppointmentQuery, throwError, token],
  );

  // converting a job
  const { query: convertEstimateQuery, loading: convertLoading } = useParameterizedQuery<AppointmentsResponse>(
    convertEstimate,
  );
  const convertToJob = useCallback(async () => {
    if (accountId && locationId && token && quote) {
      const convertEstimateRequest: ConvertEstimateRequest = {
        path: { account_id: accountId, location_id: locationId, appointment_id: quote.estimate.id },
        query: { token: token },
      };

      const { error, payload: response } = await convertEstimateQuery(convertEstimateRequest);

      if (!error && response?.appointments[0]) {
        setState({
          type: SET_APPOINTMENT,
          payload: response.appointments[0],
        });

        await rescheduleJob(response.appointments[0].id);

        return response.appointments[0];
      } else {
        throwError(`Error on: CreateAppointment, ${response?.meta?.status?.description}`);
      }
    }
  }, [accountId, convertEstimateQuery, locationId, quote, rescheduleJob, setState, throwError, token]);

  // updating quote log
  const { mutate: updateQuoteMutation } = useMutation(updateQuoteLog);
  const updateQuoteLogContent = useCallback(
    async (logId: number) => {
      if (token && locationId && accountId && quote?.id && quoteLog) {
        const request: QuoteLogPutRequest = {
          path: { account_id: accountId, quote_id: quote.id, log_id: logId },
          query: { token },
          payload: {
            content: quoteLog.content,
            signed: quoteLog.signed,
          },
        };

        const { error, payload } = await updateQuoteMutation(request);

        if (error) {
          throwError(`Error on: QuoteRatesRequest, ${payload?.meta?.status?.description}`);
        }
      }
    },
    [accountId, locationId, quote?.id, quoteLog, throwError, token, updateQuoteMutation],
  );

  // adding job to quote
  const { query: updateQuoteQuery, loading: updateQuoteLoading } = useParameterizedQuery<QuoteResponse>(updateQuote);
  const addJobToQuote = useCallback(
    async (appointmentId: number) => {
      if (accountId && locationId && token && quote) {
        const updateQuoteRequest: QuoteUpdateRequest = {
          path: { account_id: accountId, location_id: locationId, quote_id: quote.id },
          query: { token: token },
          payload: { job: { id: appointmentId } },
        };

        const { error, payload } = await updateQuoteQuery(updateQuoteRequest);

        if (!error && payload?.quotes?.length) {
          setState({
            type: SET_QUOTE,
            payload: payload.quotes[0],
          });

          await updateQuoteLogContent(payload.quotes[0].quote_logs[payload.quotes[0].quote_logs.length - 1].id);

          return payload.quotes[0];
        } else {
          throwError(`Error on: CreateAppointment, ${payload?.meta?.status?.description}`);
        }
      }
    },
    [accountId, locationId, quote, setState, throwError, token, updateQuoteLogContent, updateQuoteQuery],
  );

  const makeAppointment = useCallback(async () => {
    if (quote) {
      let isAvailable = !!quote.job.id;

      if (!quote.job.id) {
        const availabilities = await doCheckAvailability();
        isAvailable = (availabilities || []).some(
          item =>
            item.resource.id === availability?.resourceId &&
            item.schedule.start === availability.start &&
            item.schedule.end === availability.end,
        );
      }

      if (isAvailable) {
        let result: AppointmentResponseData | undefined = undefined;

        if (!appointment?.id) {
          result = quote.estimate.id ? await convertToJob() : await createJob();
        } else {
          result = appointment;
        }

        if (result?.id) {
          if (!quote.job.id) {
            await addJobToQuote(result.id);
          }

          setState({
            type: SET_IS_BOOKED,
            payload: undefined,
          });

          return result;
        }
      } else {
        showAlert('Your requested time has been filled. Please choose another time for your service.', {
          variant: 'error',
          autoHideDuration: null,
        });
        setState({ type: SET_AVAILABILITY, payload: undefined });
        history.replace(routePath.quoteAvailability(quote.id));
      }
    }
  }, [
    addJobToQuote,
    appointment,
    availability?.end,
    availability?.resourceId,
    availability?.start,
    convertToJob,
    createJob,
    doCheckAvailability,
    history,
    quote,
    setState,
    showAlert,
  ]);

  const isLoading = useMemo(() => createLoading || convertLoading || updateQuoteLoading, [
    convertLoading,
    createLoading,
    updateQuoteLoading,
  ]);

  return { convertToJob, createJob, makeAppointment, isLoading };
};
