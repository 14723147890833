import { SvgIcon } from '@material-ui/core';
import { colors } from '@chhjit/react-components';
import { memo } from 'react';

export const HighHouseIcon = memo(({ className }: { className?: string }) => (
  <SvgIcon className={className} width="67" height="64" viewBox="0 0 67 64" fill="none">
    <path
      d="M60.8701 45.9399C57.5501 45.9399 54.8501 48.6399 54.8501 51.9599C54.8501 55.1399 57.3301 57.7399 60.4601 57.9599V61.5299C60.4601 61.8799 60.7401 62.1599 61.0901 62.1599C61.4401 62.1599 61.7201 61.8799 61.7201 61.5299V57.8999C64.6301 57.4799 66.8801 54.9799 66.8801 51.9499C66.8901 48.6399 64.1901 45.9399 60.8701 45.9399Z"
      fill={colors.grey60}
      fillOpacity="0.2"
    />
    <path
      d="M9.06004 45.9399C5.74004 45.9399 3.04004 48.6399 3.04004 51.9599C3.04004 55.1399 5.52004 57.7499 8.65004 57.9599V61.5299C8.65004 61.8799 8.93004 62.1599 9.28004 62.1599C9.63004 62.1599 9.91004 61.8799 9.91004 61.5299V57.8999C12.82 57.4799 15.07 54.9799 15.07 51.9499C15.08 48.6399 12.38 45.9399 9.06004 45.9399Z"
      fill={colors.grey60}
      fillOpacity="0.2"
    />
    <path
      d="M42.9999 0H27.2799C27.0799 0 26.8799 0.08 26.7399 0.22L13.2999 13.69C13.0799 13.91 13.0199 14.23 13.1399 14.52C13.2599 14.8 13.5399 14.99 13.8399 14.99H16.9599V59.32C16.9599 59.74 17.2999 60.08 17.7199 60.08H53.2999C53.7199 60.08 54.0599 59.74 54.0599 59.32V14.99H57.1899C57.4999 14.99 57.7799 14.8 57.8999 14.51C58.0099 14.22 57.9399 13.89 57.7199 13.68L43.5199 0.21C43.3799 0.07 43.1899 0 42.9999 0Z"
      fill={colors.grey60}
      fillOpacity="0.2"
    />
    <path
      d="M24.0601 4.56004H39.1601L51.7501 16.5H12.1401L24.0601 4.56004ZM49.0101 61.6H36.2801V53.56C36.2801 51.35 34.4801 49.55 32.2701 49.55H31.6101C29.4401 49.55 27.6801 51.31 27.6801 53.48V61.6H14.9501V18.37H49.0101V61.6ZM29.2001 61.09V53.48C29.2001 52.15 30.2801 51.07 31.6101 51.07H32.2701C33.6401 51.07 34.7601 52.19 34.7601 53.56V61.09H29.2001ZM53.6601 18.03C53.9701 18.03 54.2501 17.84 54.3701 17.55C54.4901 17.26 54.4101 16.93 54.1901 16.72L39.9901 3.25004C39.8501 3.12004 39.6601 3.04004 39.4701 3.04004H23.7401C23.5401 3.04004 23.3401 3.12004 23.2001 3.26004L9.77008 16.73C9.55008 16.95 9.49008 17.27 9.61008 17.56C9.73008 17.84 10.0101 18.03 10.3101 18.03H13.4301V62.36C13.4301 62.78 13.7701 63.12 14.1901 63.12H49.7701C50.1901 63.12 50.5301 62.78 50.5301 62.36V18.03H53.6601Z"
      fill={colors.grey60}
      fillOpacity="0.6"
    />
    <path
      d="M19.4299 23.13H23.4599V27.16H19.4299V23.13ZM18.6699 28.68H24.2199C24.6399 28.68 24.9799 28.34 24.9799 27.92V22.37C24.9799 21.95 24.6399 21.61 24.2199 21.61H18.6699C18.2499 21.61 17.9099 21.95 17.9099 22.37V27.92C17.9099 28.34 18.2499 28.68 18.6699 28.68Z"
      fill={colors.grey60}
      fillOpacity="0.6"
    />
    <path
      d="M40.49 23.13H44.52V27.16H40.49V23.13ZM39.73 28.68H45.28C45.7 28.68 46.04 28.34 46.04 27.92V22.37C46.04 21.95 45.7 21.61 45.28 21.61H39.73C39.31 21.61 38.97 21.95 38.97 22.37V27.92C38.97 28.34 39.31 28.68 39.73 28.68Z"
      fill={colors.grey60}
      fillOpacity="0.6"
    />
    <path
      d="M19.4299 33.8301H23.4599V43.9001H19.4299V33.8301ZM18.6699 45.4201H24.2199C24.6399 45.4201 24.9799 45.0801 24.9799 44.6601V33.0701C24.9799 32.6501 24.6399 32.3101 24.2199 32.3101H18.6699C18.2499 32.3101 17.9099 32.6501 17.9099 33.0701V44.6501C17.9099 45.0801 18.2499 45.4201 18.6699 45.4201Z"
      fill={colors.grey60}
      fillOpacity="0.6"
    />
    <path
      d="M29.9699 33.8301H34V43.9001H29.9699V33.8301ZM29.2099 45.4201H34.7599C35.1799 45.4201 35.52 45.0801 35.52 44.6601V33.0701C35.52 32.6501 35.1799 32.3101 34.7599 32.3101H29.2099C28.7899 32.3101 28.45 32.6501 28.45 33.0701V44.6501C28.45 45.0801 28.7899 45.4201 29.2099 45.4201Z"
      fill={colors.grey60}
      fillOpacity="0.6"
    />
    <path
      d="M40.49 33.8301H44.52V43.9001H40.49V33.8301ZM39.73 45.4201H45.28C45.7 45.4201 46.04 45.0801 46.04 44.6601V33.0701C46.04 32.6501 45.7 32.3101 45.28 32.3101H39.73C39.31 32.3101 38.97 32.6501 38.97 33.0701V44.6501C38.97 45.0801 39.31 45.4201 39.73 45.4201Z"
      fill={colors.grey60}
      fillOpacity="0.6"
    />
    <path
      d="M19.4299 49.22H23.4599V56.29H19.4299V49.22ZM18.6699 57.81H24.2199C24.6399 57.81 24.9799 57.4699 24.9799 57.0499V48.4599C24.9799 48.0399 24.6399 47.7 24.2199 47.7H18.6699C18.2499 47.7 17.9099 48.0399 17.9099 48.4599V57.0499C17.9099 57.4699 18.2499 57.81 18.6699 57.81Z"
      fill={colors.grey60}
      fillOpacity="0.6"
    />
    <path
      d="M40.49 49.22H44.52V56.29H40.49V49.22ZM39.73 57.81H45.28C45.7 57.81 46.04 57.4699 46.04 57.0499V48.4599C46.04 48.0399 45.7 47.7 45.28 47.7H39.73C39.31 47.7 38.97 48.0399 38.97 48.4599V57.0499C38.97 57.4699 39.31 57.81 39.73 57.81Z"
      fill={colors.grey60}
      fillOpacity="0.6"
    />
    <path
      d="M6.88 57.63V56.6199L8.71 53.2799C8.88 52.9699 8.76 52.5899 8.46 52.4199C8.15 52.2499 7.77 52.3599 7.6 52.6699L6.88 53.9799V51.7299C6.88 51.38 6.6 51.0999 6.25 51.0999C5.9 51.0999 5.62 51.3899 5.62 51.7299V55.25L4.02 53.5999C3.78 53.3499 3.38 53.34 3.12 53.58C2.87 53.82 2.86 54.22 3.1 54.47L5.53 57L5.6 57.0499V57.6699C3.17 57.4599 1.26 55.4399 1.26 52.9599C1.26 50.3399 3.39 48.2099 6.01 48.2099C8.63 48.2099 10.76 50.3399 10.76 52.9599C10.77 55.2999 9.09 57.23 6.88 57.63ZM6.02 46.95C2.7 46.95 0 49.65 0 52.97C0 56.15 2.48 58.76 5.61 58.97V62.54C5.61 62.89 5.89 63.1699 6.24 63.1699C6.59 63.1699 6.87 62.88 6.87 62.54V58.93C9.78 58.51 12.03 56.0099 12.03 52.9799C12.03 49.65 9.34 46.95 6.02 46.95Z"
      fill={colors.grey60}
      fillOpacity="0.6"
    />
    <path
      d="M58.69 57.63V56.6199L60.5201 53.2799C60.6901 52.9699 60.5801 52.5899 60.2701 52.4199C59.96 52.2499 59.58 52.3599 59.41 52.6699L58.69 53.9799V51.7299C58.69 51.38 58.4101 51.0999 58.0601 51.0999C57.7101 51.0999 57.43 51.38 57.43 51.7299V55.25L55.84 53.59C55.6 53.34 55.2 53.33 54.94 53.57C54.69 53.8099 54.68 54.21 54.92 54.47L57.35 57L57.42 57.0499V57.6699C54.99 57.4599 53.0801 55.4399 53.0801 52.9599C53.0801 50.3399 55.2101 48.2099 57.8301 48.2099C60.4501 48.2099 62.5801 50.3399 62.5801 52.9599C62.5801 55.2999 60.9 57.23 58.69 57.63ZM63.84 52.97C63.84 49.65 61.14 46.95 57.82 46.95C54.5 46.95 51.8 49.65 51.8 52.97C51.8 56.15 54.28 58.75 57.41 58.97V62.54C57.41 62.89 57.6901 63.1699 58.0401 63.1699C58.3901 63.1699 58.67 62.89 58.67 62.54V58.93C61.6 58.5 63.84 56 63.84 52.97Z"
      fill={colors.grey60}
      fillOpacity="0.6"
    />
  </SvgIcon>
));
