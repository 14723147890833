import { Typography, Grid } from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars';
import { Button, Loader } from '@chhjit/react-components';

import { TimePickerProps } from './TimePicker.types';
import { useStyles } from './TimePicker.styles';

export function TimePicker({ period, schedule, isLoading, onTimeSelected }: TimePickerProps) {
  const styles = useStyles();
  return (
    <Grid container direction="column" className={styles.container}>
      <Grid item xs="auto">
        <Typography variant="subtitle2" color="secondary" className={styles.title}>
          {period}
        </Typography>
      </Grid>

      <Grid item xs className={styles.timeContainer}>
        {isLoading && <Loader />}
        {!isLoading && schedule.length !== 0 && (
          <Scrollbars autoHide autoHideTimeout={800} autoHideDuration={200}>
            {schedule.map((time, index) => (
              <div className={styles.timeButton} key={`${index}-${time.period}`}>
                <Button
                  classes={{ root: time.selected ? 'selected' : undefined }}
                  fullWidth
                  buttonType="twoTone"
                  onClick={() => onTimeSelected(time)}
                >
                  {time.friendly}
                </Button>
              </div>
            ))}
          </Scrollbars>
        )}
        {!isLoading && schedule.length === 0 && (
          <Typography variant="body1" className={styles.noAvailability}>
            No Availability
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
