import { colors } from '@chhjit/react-components';
import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  detailsPanel: {
    marginTop: -22,
  },
  detailsButton: {
    textAlign: 'center',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '&:not(:last-child)': {
      borderRight: `1px solid ${colors.grey30}`,
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: 'initial',
      borderBottom: `1px solid ${colors.grey30}`,

      '&:not(:last-child)': {
        borderRight: 'none',
      },
    },
  },
  callUs: {
    flex: 2,

    [theme.breakpoints.down('sm')]: {
      flex: 'initial',
    },
  },
  phoneNumber: {
    textDecoration: 'underline',
  },
  pricingButton: {
    textTransform: 'none',
  },
}));
