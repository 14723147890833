import { Grid, Typography, Hidden } from '@material-ui/core';
import LocalShipping from '@material-ui/icons/LocalShipping';
import { Button, useDialogDispatch } from '@chhjit/react-components';
import { memo, useState } from 'react';

import { useRequestEta } from 'hooks/useRequestEta/useRequestEta';

import { CheckEtaSentDialog } from './checkEtaSentDialog/CheckEtaSentDialog';
import { RequestEtaComponentProps } from './CheckEtaDialog.types';
import { useStyles } from './CheckEtaDialog.styles';

export const CheckEtaDialog = memo(({ appointment }: RequestEtaComponentProps) => {
  const styles = useStyles();
  const setDialog = useDialogDispatch();
  const requestEta = useRequestEta();

  const closeDialog = () => {
    setDialog({ open: false });
  };

  const [sending, setSendingStatus] = useState(false);
  const sendEta = async () => {
    setSendingStatus(true);
    await requestEta(appointment.id);
    setDialog({ open: true, variant: 'schedule', dialogContent: <CheckEtaSentDialog /> });
    setSendingStatus(false);
  };

  return (
    <Grid container direction="row" alignItems="center" justify="center" spacing={4} className={styles.dialogContainer}>
      <Grid item>
        <Typography variant="h3" color="secondary">
          Check the status <span className={styles.subtitle}>of your local HUNKS</span>
        </Typography>
      </Grid>

      <Hidden smDown>
        <Grid item>
          <LocalShipping className={styles.checkIcon} color="secondary" />
        </Grid>
      </Hidden>

      <Grid item>
        <Typography variant="body1" color="secondary" className={styles.dialogDescription}>
          We can send a message to your local team to reach out to you with an ETA. Would you like to continue?
        </Typography>
      </Grid>

      <Grid item container spacing={1} alignItems="center" className={styles.etaButtonsContainer}>
        <Grid item>
          <Button buttonType="outlined" onClick={() => sendEta()} isLoading={sending}>
            Yes
          </Button>
        </Grid>
        <Grid item>
          <Button buttonType="outlined" onClick={() => closeDialog()}>
            No
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
});
