import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    textAlign: 'center',
  },
  description: {
    textAlign: 'center',
    margin: '48px auto 0',
  },
});
