import { Dialog } from '@chhjit/react-components';
import { Typography } from '@material-ui/core';
import { memo } from 'react';

import { TruckActionIcon } from 'assets/icons/TruckActionIcon';

import { useProcessSpinnerStyles } from './styles';

export const ProcessSpinner = memo(() => {
  const styles = useProcessSpinnerStyles();

  return (
    <Dialog variant="custom" open disableBackdropClick>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.truckContainer}>
            <TruckActionIcon className={styles.truck} />
          </div>
          <Typography className={styles.text} variant="h2">
            Processing the request...
          </Typography>
        </div>
      </div>
    </Dialog>
  );
});
