import { Ref, memo, useCallback, useRef } from 'react';
import SignaturePad from 'react-signature-canvas';
import { Typography } from '@material-ui/core';
import { Button } from '@chhjit/react-components';

import { SignatureCanvas } from '../signature-canvas';

import { useStyles } from './styles';
import { SignDialogProps } from './types';

export const SignDialog = memo(({ onSubmit, onClose }: SignDialogProps) => {
  const styles = useStyles();

  const canvasRef = useRef<SignaturePad>();

  const handleSubmit = useCallback(() => {
    canvasRef.current?.getTrimmedCanvas().toBlob(blob => {
      if (blob) {
        onSubmit(blob);
      }
    });
  }, [onSubmit]);

  return (
    <div>
      <Typography variant="body1">Please sign the box below.</Typography>

      <div className={styles.signatureCanvasContainer}>
        <SignatureCanvas ref={canvasRef as Ref<SignaturePad> | undefined} />
      </div>

      <div className={styles.actionButtonsContainer}>
        <Button buttonType="text" color="primary" onClick={onClose}>
          Close
        </Button>

        <Button buttonType="twoTone" fullWidth className={styles.submitButton} onClick={handleSubmit}>
          Accept
        </Button>
      </div>
    </div>
  );
});
