import { colors } from '@chhjit/react-components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  root: {
    margin: '40px 0',
    textAlign: 'center',
  },
  pricePoint: {},
  container: {},
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notAvailable: {
    color: colors.basic.black,
    fontWeight: 'normal',
  },
});
