import { memo } from 'react';
import { Typography } from '@material-ui/core';

import { useStyles } from './CallUsComponent.styles';

export const CallUsComponent = memo(({ message }: { message: string }) => {
  const styles = useStyles();
  return (
    <div>
      <Typography variant="body1">{message}</Typography>
      <Typography variant="body1">
        Call us: <span className={styles.phoneNumber}>833-626-1326</span>
      </Typography>
    </div>
  );
});
