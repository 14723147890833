import { memo, useCallback, useMemo, useState } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import { Redirect, useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { format } from 'date-fns';
import moment from 'moment';
import { Loader } from '@chhjit/react-components';

import { AppointmentsResponse, UpdateAppointmentRequest } from 'api/actions/appointments/AppointmentsActions.types';
import { updateAppointment } from 'api/actions/appointments/AppointmentsActions';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { AppRoute } from 'routing/AppRoute.enum';
import { PageContent } from 'ui/page-content/PageContent';
import { displayDate } from 'utils/formatDate/FormatDate';
import { DashedSeparator } from 'ui/dashedSeparator/DashedSeparator';
import { AppointmentsTableContainer } from 'app/appointmentsTable/AppointmentsTableContainer';
import { useMessagesDispatch } from 'hooks/useMessages/useMessages';
import { useAsyncError } from 'hooks/useAsyncError/useAsyncError';
import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';
import { useLocationState } from 'hooks/useLocation/useLocaiton';
import { AppointmentCategory } from 'api/types/appointment';

import { useStyles } from './CancelAppointment.styles';
import { CancelAppointmentProps } from './CancelAppointment.types';
import { CancelAppointmentForm } from './CancelAppointmentForm/CancelAppointmentForm';

export const CancelAppointment = memo(({ appointment }: CancelAppointmentProps) => {
  const styles = useStyles();

  const history = useHistory();

  const { accountId, locationId, token } = useAuthState();
  const { location, isLoading } = useLocationState();

  const setMessage = useMessagesDispatch();

  const { loading: isDuringRequest, query } = useParameterizedQuery(updateAppointment);

  const [success, setSuccess] = useState(false);
  const [reasonId, setReasonId] = useState<number>();

  const throwError = useAsyncError();
  const { toggles } = useFeatureFlags();

  const refundAmount = useMemo(() => {
    const isValidCatgeory =
      appointment.category.id === AppointmentCategory['labor'] ||
      appointment.category.id === AppointmentCategory['moveLabor'] ||
      appointment.category.id === AppointmentCategory['move'];

    const isJob = appointment.type === 'JOB';

    const refund_day = moment(new Date(appointment.original_date).toDateString()).subtract(
      appointment.refund_days - 1,
      'days',
    );
    const refund_today = moment(new Date().toDateString());
    const isRefundWindow = refund_day.diff(refund_today) >= 0;

    const hasOnePayment = appointment.payments.length === 1;
    const payment = appointment.payments[0]?.payment;
    const isValidPayment = payment?.transaction_id && payment?.transaction_id !== '' && !payment.is_refunded;

    if (
      toggles.Payments_AllowPrepaymentRefunds &&
      location?.move_settings[0].pre_payment.allow_auto_refund &&
      isValidCatgeory &&
      isJob &&
      isRefundWindow &&
      hasOnePayment &&
      isValidPayment
    ) {
      return payment.amount;
    }
  }, [
    appointment.category.id,
    appointment.original_date,
    appointment.payments,
    appointment.refund_days,
    appointment.type,
    location?.move_settings,
    toggles.Payments_AllowPrepaymentRefunds,
  ]);

  const handleSubmit = useCallback(
    async (defaultReasonId?: number) => {
      if (defaultReasonId) {
        setReasonId(defaultReasonId);
      }

      if (appointment && reasonId && accountId && locationId && token) {
        const request: UpdateAppointmentRequest = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          path: { account_id: accountId, appointment_id: appointment.id, location_id: locationId },
          query: { token: token },
          payload: {
            account: { id: accountId },
            category: { id: appointment.category.id },
            // eslint-disable-next-line @typescript-eslint/naming-convention
            start_date: format(appointment.startDate, 'yyyy-MM-dd HH:mm:ss'),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            end_date: format(appointment.endDate, 'yyyy-MM-dd HH:mm:ss'),
            origin: { id: appointment.origin.id },
            type: appointment.type,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            ...(appointment.type === 'EST' && { est_status: { id: 7 } }),
            ...(appointment.type !== 'EST' && { status: { id: 5 } }),
            // eslint-disable-next-line @typescript-eslint/naming-convention
            lost_reason: { id: reasonId },
            refund_payments: !!(toggles.Payments_AllowPrepaymentRefunds && refundAmount),
          },
        };

        const { error, payload } = await query(request);

        const isRefundError = !!payload?.meta.refund_error;

        if ((!error || isRefundError) && payload?.appointments) {
          if (isRefundError) {
            setTimeout(() => {
              history.replace(AppRoute.dashboard, { appointmentId: appointment.id });
            }, 0);

            throwError(`Error on: UpdateAppointmentRequest, ${payload?.meta?.status?.description}`, {
              description: 'Your job has been canceled, but there is a problem with the automatic refund.',
              details: 'Please contact us by calling 833-626-1326 to initiate a refund.',
              action: {
                title: 'Call us',
                onClick: () => (window.location.href = 'tel:833-626-1326'),
              },
            });
          } else {
            setSuccess(true);
          }
        } else {
          throwError(`Error on: UpdateAppointmentRequest, ${payload?.meta?.status?.description}`);
        }
      }
    },
    [
      token,
      accountId,
      locationId,
      appointment,
      reasonId,
      refundAmount,
      history,
      toggles.Payments_AllowPrepaymentRefunds,
      query,
      throwError,
    ],
  );

  const description = useMemo(() => {
    if (!toggles.Payments_AllowPrepaymentRefunds) {
      return !reasonId
        ? 'Here you cancel your appointment'
        : 'Before we process your cancellation, check out what you may be missing out on';
    }
  }, [reasonId, toggles.Payments_AllowPrepaymentRefunds]);

  if (reasonId) {
    handleSubmit(reasonId);
    setReasonId(0);
  } else if (success) {
    setMessage({ message: 'Appointment cancelled', type: 'success' });
    return <Redirect to={{ pathname: AppRoute.dashboard, state: { appointmentId: appointment.id } }} />;
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div data-testid="cancel" className={styles.root}>
      <PageContent
        title="Cancel appointment"
        subtitle={displayDate(appointment.startDate)}
        description={description}
        appointmentId={appointment.id}
        closable
      >
        {toggles.Payments_AllowPrepaymentRefunds && (
          <div className={styles.description}>
            <Typography variant="h4">We are sorry to see you go.</Typography>
            <br />
            <Typography>
              Thank you for considering us. We hope that you contact us for any future junk removal or moving services
              you need in the future.
            </Typography>
          </div>
        )}

        <CancelAppointmentForm
          onCancel={handleSubmit}
          isDuringRequest={isDuringRequest}
          allowRefund={toggles.Payments_AllowPrepaymentRefunds}
          refundAmount={refundAmount}
        />

        <DashedSeparator />

        <AppointmentsTableContainer appointments={[appointment]} isLoading={false} isSingleAppointment />
      </PageContent>
    </div>
  );
});
