import { memo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Loader } from '@chhjit/react-components';

import { FindAccount } from 'app/findAccount/FindAccount';
import { Dashboard } from 'app/dashboard/Dashboard';
import { LoginController } from 'context/auth/loginController/LoginController';
import { CancelAppointment } from 'app/cancelAppointment/CancelAppointment';
import { CancelAppointmentProps } from 'app/cancelAppointment/CancelAppointment.types';
import { RescheduleAppointmentProps } from 'app/rescheduleAppointment/RescheduleAppointment.types';
import { RescheduleAppointment } from 'app/rescheduleAppointment/RescheduleAppointment';
import { DashboardProps } from 'app/dashboard/Dashboard.types';
import { Quote } from 'app/quote/Quote';
import { routePath } from 'utils/routePath/routePath';
import { PreCancelAppointment } from 'app/preCancelAppointment/PreCancelAppointment';
import { useFeatureFlags } from 'hooks/useFeatureFlags/useFeatureFlags';

import { AppRoute } from './AppRoute.enum';

export const AppRoutes = memo(() => {
  const { toggles } = useFeatureFlags();

  return (
    <Switch>
      <Route path={AppRoute.findAccount} exact component={FindAccount} />

      <Route
        path={AppRoute.dashboard}
        exact
        render={props => (
          <Dashboard
            appointmentId={(props.location.state as DashboardProps)?.appointmentId}
            isConfirmed={(props.location.state as DashboardProps)?.isConfirmed}
            beginReview={(props.location.state as DashboardProps)?.beginReview}
            makePayment={(props.location.state as DashboardProps)?.makePayment}
            paymentAmount={(props.location.state as DashboardProps)?.paymentAmount}
            tipEnabled={(props.location.state as DashboardProps)?.tipEnabled}
          />
        )}
      />

      <Route path={AppRoute.login} exact component={LoginController} />

      {toggles.Payments_AllowPrepaymentRefunds && (
        <Route
          path={AppRoute.preCancel}
          exact
          render={props => (
            <PreCancelAppointment appointment={(props.location.state as CancelAppointmentProps).appointment} />
          )}
        />
      )}

      <Route
        path={AppRoute.cancel}
        exact
        render={props => (
          <CancelAppointment appointment={(props.location.state as CancelAppointmentProps).appointment} />
        )}
      />

      <Route
        path={AppRoute.reschedule}
        exact
        render={props => (
          <RescheduleAppointment appointment={(props.location.state as RescheduleAppointmentProps).appointment} />
        )}
      />

      <Route path={routePath.quote()} component={Quote} />

      <Route
        path={AppRoute.chhj}
        component={() => {
          window.location.href = 'https://www.collegehunkshaulingjunk.com/';
          return <Loader />;
        }}
      />

      <Route
        path={AppRoute.booking}
        component={() => {
          window.location.href = 'https://book.collegehunkshaulingjunk.com/get-started/';
          return <Loader />;
        }}
      />

      <Redirect to={AppRoute.findAccount} />
    </Switch>
  );
});
