import { colors } from '@chhjit/react-components';
import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  buttonPanel: {
    cursor: 'pointer',
  },
  icon: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 35,
    height: 35,
  },
  text: {
    color: colors.grey140,
  },
}));
