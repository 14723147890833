import { Grid, Typography } from '@material-ui/core';
import { Button } from '@chhjit/react-components';
import { useMemo } from 'react';

import { CustomError } from 'utils/customError/customError';

import { useStyles } from './ErrorBoundaryFallback.styles';
import { ErrorBoundaryFallbackProps } from './ErrorBoundaryFallback.types';

export const ErrorBoundaryFallback = ({ error, clearError }: ErrorBoundaryFallbackProps) => {
  const styles = useStyles();

  const description = useMemo(
    () => (error instanceof CustomError && error.description) || 'Opps! Something went wrong!',
    [error],
  );

  const details = useMemo(
    () =>
      (error instanceof CustomError && error.details) ||
      'We are working on the problem. Please try to reload or try again later!',
    [error],
  );

  const action = useMemo(
    () => (error instanceof CustomError && error.action) || { title: 'Reload', onClick: clearError },
    [error, clearError],
  );

  return (
    <div className={styles.root}>
      <Grid spacing={3} container alignContent="center" justify="center">
        <Grid item xs={12}>
          <Typography variant="h1" color="secondary" align="center">
            Error
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            <strong>{description}</strong>
          </Typography>

          <Typography variant="body1" align="center">
            {details}
          </Typography>
        </Grid>

        <Grid item>
          <Button buttonType="outlined" onClick={action.onClick}>
            {action.title}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
