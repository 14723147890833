import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { Loader } from '@chhjit/react-components';

import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { AppRoute } from 'routing/AppRoute.enum';
import { useMessagesDispatch } from 'hooks/useMessages/useMessages';
import { useConfirmAppointment } from 'hooks/useConfirmAppointment/useConfirmAppointment';
import { routePath } from 'utils/routePath/routePath';

import { getUrlParams } from './LoginController.utils';

export const LoginController = memo(() => {
  const { isAuthorized, isAuthorizing } = useAuthState();
  const { search } = useLocation();
  const setMessage = useMessagesDispatch();
  const confirmAppointment = useConfirmAppointment();
  const [isInitialized, setIsInitialized] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [beginReview, setBeginReview] = useState(false);
  const [makePayment, setMakePayment] = useState(false);

  const urlParams = useMemo(() => getUrlParams(search), [search]);

  const confirm = useCallback(async () => {
    if (urlParams?.beginReview && urlParams.appointmentId) {
      setBeginReview(true);
    }

    if (urlParams?.makePayment && urlParams.appointmentId) {
      setMakePayment(true);
    }

    if (urlParams?.confirmAppointment && urlParams.appointmentId && confirmAppointment) {
      const confirmed = await confirmAppointment(true, urlParams.appointmentId);

      if (confirmed) {
        setIsConfirmed(true);
      }
    }

    setIsInitialized(true);
  }, [
    confirmAppointment,
    urlParams?.appointmentId,
    urlParams?.beginReview,
    urlParams?.confirmAppointment,
    urlParams?.makePayment,
  ]);

  useEffect(() => {
    if (!isAuthorizing && isAuthorized) {
      confirm();
    }
  }, [confirm, isAuthorized, isAuthorizing]);

  if (isInitialized) {
    if (urlParams?.signEstimate && urlParams?.quoteId) {
      return <Redirect to={routePath.quoteEstimate(urlParams.quoteId)} />;
    }

    return (
      <Redirect
        to={{
          pathname: AppRoute.dashboard,
          state: {
            appointmentId: urlParams?.appointmentId,
            isConfirmed,
            beginReview,
            makePayment,
            paymentAmount: urlParams?.paymentAmount,
            tipEnabled: urlParams?.tipEnabled,
          },
        }}
      />
    );
  }

  if (!isAuthorizing && !isAuthorized) {
    setMessage({ message: 'Your magic link is not valid', type: 'warning' });
    return <Redirect to={AppRoute.findAccount} />;
  }
  return <Loader />;
});
