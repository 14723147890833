import { memo } from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

import { DashedSeparator } from 'ui/dashedSeparator/DashedSeparator';
import { dateSame, datePast } from 'utils/formatDate/FormatDate';
import { AppointmentPricingButton } from 'app/appointmentManagement/appointmentPricingButton/AppointmentPricingButton';
import { RescheduleAppointmentButton } from 'app/appointmentManagement/rescheduleAppointmentButton/RescheduleAppointmentButton';
import { CheckEtaButton } from 'app/appointmentManagement/checkEtaButton/CheckEtaButton';
import { CancelAppointmentButton } from 'app/appointmentManagement/cancelAppointmentButton/CancelAppointmentButton';
import { CallUsComponent } from 'app/appointmentManagement/callUsComponent/CallUsComponent';
import { AppointmentCategory } from 'api/types/appointment';

import { useStyles } from './appointmentManagementPanel.styles';
import { AppointmentManagePanelProps } from './appointmentManagementPanel.types';

export const AppointmentManagementPanel = memo(({ appointment }: AppointmentManagePanelProps) => {
  const styles = useStyles();
  const movingWithoutDestination =
    (appointment.category.id === AppointmentCategory.move ||
      appointment.category.id === AppointmentCategory.moveLabor) &&
    appointment.destination.id === 0;
  const canCancel = !datePast(appointment.startDate);
  const canReschedule = !datePast(appointment.startDate) && !movingWithoutDestination;
  const canCheckEta = dateSame(appointment.startDate);
  const canSeePricing = appointment.type !== 'EST';

  return (
    <div data-testid="manage-panel">
      <DashedSeparator />
      <Grid container className={styles.detailsPanel}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md="auto" className={styles.detailsButton}>
              {canSeePricing && <AppointmentPricingButton appointment={appointment} />}
            </Grid>

            {canReschedule && canCancel && (
              <>
                <Grid item xs={12} md="auto" className={styles.detailsButton}>
                  <CancelAppointmentButton appointment={appointment} />
                </Grid>
                <Grid item xs={12} md="auto" className={styles.detailsButton}>
                  <RescheduleAppointmentButton appointment={appointment} />
                </Grid>
              </>
            )}

            {!canReschedule && canCancel && (
              <>
                <Grid item xs={12} md="auto" className={clsx(styles.detailsButton, styles.callUs)}>
                  <CallUsComponent message={`Want to reschedule ${appointment.category.name.toLowerCase()}?`} />
                </Grid>
                <Grid item xs={12} md="auto" className={styles.detailsButton}>
                  <CancelAppointmentButton appointment={appointment} />
                </Grid>
              </>
            )}

            {!canReschedule && !canCancel && (
              <Grid item xs={12} md="auto" className={clsx(styles.detailsButton, styles.callUs)}>
                <CallUsComponent message={`Want to cancel or reschedule ${appointment.category.name.toLowerCase()}?`} />
              </Grid>
            )}

            {canCheckEta && (
              <Grid item xs={12} md="auto" className={styles.detailsButton}>
                <CheckEtaButton appointment={appointment} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
});
