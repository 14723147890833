import { colors } from '@chhjit/react-components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  dialogContainer: {
    textAlign: 'center',
    margin: '30px 0',
    maxWidth: '100%',
  },
  subtitle: {
    color: colors.primary.main,
  },
  checkIcon: {
    fontSize: '6rem',
    filter: `drop-shadow(5px 4px 0px ${colors.secondary.main}55)`,
  },
  dialogDescription: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  etaButtonsContainer: {
    justifyContent: 'center',
  },
});
