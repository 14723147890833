import React from 'react';

import { CustomError, CustomErrorOptions } from 'utils/customError/customError';

export const useAsyncError = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setError] = React.useState();

  return React.useCallback(
    (message: string, options?: CustomErrorOptions) => {
      setError(() => {
        const error = new CustomError(message, options);

        throw error;
      });
    },
    [setError],
  );
};
