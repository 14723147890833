/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  AddressAutocomplete,
  AddressAutocompleteOnSelectValues,
  Input,
  NewSelect,
  SelectOptionType,
} from '@chhjit/react-components';
import { FormControl, FormHelperText, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

import { getValidationText } from 'utils/formHelpers/getValidationText';
import { getSelectValue } from 'utils/formHelpers/getSelectValue';

import { AccountDataFormValues, AccountDataFormProps } from './AccountDataForm.types';
import { accountDataFormSchema } from './AccountDataForm.utils';

export const useAccountDataForm = ({ addressOptions, defaultValues }: AccountDataFormProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    control,
    formState: { isValid, errors: formErrors },
    register,
    watch,
    setValue,
    getValues,
  } = useForm<AccountDataFormValues>({
    mode: 'onChange',
    resolver: yupResolver(accountDataFormSchema),
    defaultValues: defaultValues,
  });

  const stateWatched = watch('state');
  const countryWatched = watch('country');

  const countryOptions = useMemo(() => {
    return addressOptions.map(addr => ({
      label: addr.countryName,
      value: addr.countryValue,
    }));
  }, [addressOptions]);

  const stateOptions = useMemo(
    () =>
      addressOptions.map(addr => ({
        label: addr.countryName,
        options: addr.states.map(state => ({
          label: state.name,
          value: state.value,
        })),
      })),
    [addressOptions],
  );

  const onAddressAutocompleteSelect = useCallback(
    (values: AddressAutocompleteOnSelectValues | null) => {
      setValue('address', values?.fullAddress.address ?? '', { shouldValidate: true });

      if (!values) {
        return;
      }

      setValue('address', values.fullAddress.address ?? '', { shouldValidate: true });
      setValue('city', values.fullAddress.city ?? '', { shouldValidate: true });
      setValue('state', values.fullAddress.state ?? '', { shouldValidate: true });
      setValue('postal', values.fullAddress.postal ?? '', { shouldValidate: true });
      setValue('country', values.fullAddress.country ?? '', { shouldValidate: true });
    },
    [setValue],
  );

  useEffect(() => {
    register('address');
    register('state');
    register('country');
  }, []);

  const renderAccountDataForm = () => (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Grid container spacing={isMobile ? 2 : 3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!formErrors.firstName}>
              <Controller
                name="firstName"
                control={control}
                render={({ field }) => (
                  <Input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    variant="outlined"
                    label="First name"
                    error={!!formErrors.firstName}
                    size="medium"
                  />
                )}
              />
              <FormHelperText>{getValidationText(formErrors, 'firstName')}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!formErrors.lastName}>
              <Controller
                name="lastName"
                control={control}
                render={({ field }) => (
                  <Input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    variant="outlined"
                    label="Last name"
                    error={!!formErrors.lastName}
                    size="medium"
                  />
                )}
              />
              <FormHelperText>{getValidationText(formErrors, 'lastName')}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <FormControl fullWidth error={!!formErrors.address}>
          <AddressAutocomplete
            label="Address"
            name="address"
            inputValue={defaultValues?.address ?? ''}
            hasError={!!formErrors.address}
            onSelect={onAddressAutocompleteSelect}
            onBlur={() => {}}
          />
          <FormHelperText>{getValidationText(formErrors, 'address')}</FormHelperText>
        </FormControl>
      </Grid>

      <Grid item>
        <FormControl fullWidth error={!!formErrors.address2}>
          <Controller
            name="address2"
            control={control}
            render={({ field }) => (
              <Input
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                variant="outlined"
                label="Suite/apt"
                error={!!formErrors.address2}
                size="medium"
              />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item>
        <Grid container spacing={isMobile ? 2 : 3}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth error={!!formErrors.city}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <Input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    variant="outlined"
                    label="City"
                    error={!!formErrors.city}
                    size="medium"
                  />
                )}
              />
              <FormHelperText>{getValidationText(formErrors, 'city')}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <NewSelect
                label="State"
                name="state"
                // @ts-ignore
                options={stateOptions}
                value={getSelectValue(stateWatched, stateOptions)}
                onChange={(val: SelectOptionType<string> | null) => {
                  if (val?.value) {
                    setValue('state', val.value, { shouldValidate: true });
                  }
                }}
              />
              <FormHelperText>{getValidationText(formErrors, 'state')}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControl fullWidth error={!!formErrors.postal}>
              <Controller
                name="postal"
                control={control}
                render={({ field }) => (
                  <Input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    variant="outlined"
                    label="Zip/postal"
                    error={!!formErrors.postal}
                    size="medium"
                  />
                )}
              />
              <FormHelperText>{getValidationText(formErrors, 'postal')}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container spacing={isMobile ? 2 : 3}>
          <Grid item xs={12} sm={4}>
            <FormControl fullWidth>
              <NewSelect
                label="Country"
                name="country"
                options={countryOptions}
                value={getSelectValue(countryWatched, countryOptions)}
                onChange={(val: SelectOptionType<string> | null) => {
                  if (val?.value) {
                    setValue('country', val.value, { shouldValidate: true });
                  }
                }}
              />
              <FormHelperText>{getValidationText(formErrors, 'country')}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  return { isValid, errors: formErrors, renderAccountDataForm, getValues };
};
