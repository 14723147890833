import { colors } from '@chhjit/react-components';
import { Theme, makeStyles } from '@material-ui/core';

export const useTimeSlotStyles = makeStyles((theme: Theme) => ({
  hourBox: {
    textAlign: 'center',

    '&:not(:first-child)': {
      marginTop: 16,
    },

    [theme.breakpoints.down('xs')]: {
      paddingRight: 7,
    },
  },

  selected: {
    '& span': {
      backgroundColor: colors.primary.main,
      color: colors.basic.white,
    },
  },
}));
