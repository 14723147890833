import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Button } from '@chhjit/react-components';
import { Skeleton } from '@material-ui/lab';
import { isAfter, isBefore } from 'date-fns';
import moment from 'moment';

import { AvailabilityResponseData } from 'api/actions/availability/AvailabilityActions.types';
import { SchedulePeriod } from 'api/types/schedule';

import { AvailableHoursProps } from './model';
import { AvailabilityColumn, PeriodSelector, TimeSlotButton, TimeSlotSkeleton } from './ui';

export const AvailableHours = memo(
  ({ arrivalWindows, items, loading, disabled, onSelect, selected }: AvailableHoursProps) => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('sm'), {
      noSsr: true,
    });
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
      noSsr: true,
    });

    const sortedHours = useMemo(
      () =>
        items.reduce<Record<SchedulePeriod, AvailabilityResponseData[]>>(
          (acc, curr) => {
            acc[curr.schedule.period].push(curr);
            return acc;
          },
          {
            morning: [],
            afternoon: [],
            evening: [],
          },
        ),
      [items],
    );

    const isSelected = useCallback(
      (data: AvailabilityResponseData) =>
        data.schedule.start === selected?.start &&
        data.schedule.end === selected?.end &&
        data.resource.id === selected?.resourceId,
      [selected?.end, selected?.resourceId, selected?.start],
    );

    const [selectedPriod, selectPeriod] = useState<SchedulePeriod>('morning');

    useEffect(() => {
      if (selected) {
        const slot = items.find(item => isSelected(item));
        selectPeriod(slot?.schedule.period || 'morning');
      }
    }, [items]);

    const periods = useMemo(() => Object.keys(sortedHours) as SchedulePeriod[], [sortedHours]);

    const mostUsed = useMemo(() => {
      const list = items.length >= 4 ? items.filter(item => item?.schedule.most_used) : items;

      if (list.length < 4 && items.length >= 4) {
        let index = 0;

        while (list.length < 4) {
          for (let i = 0; i < periods.length; i++) {
            const slot = sortedHours[periods[i]][index];

            if (slot && !slot?.schedule.most_used) {
              list.push(slot);
            }

            if (list.length === 4) {
              break;
            }
          }

          index += 1;
        }
      }

      return list.sort((a, b) => {
        if (a.schedule.start < b.schedule.start) {
          return -1;
        } else {
          return 1;
        }
      });
    }, [items]);

    const [showMostUsed, setShowMostUsed] = useState(true);

    useEffect(() => {
      if (loading) {
        setShowMostUsed(true);
      }
    }, [loading]);

    const arrivalText = useMemo(() => {
      if (loading) {
        return <Skeleton width={isMobile ? 200 : 360} variant="text" />;
      }

      if (!items.length) {
        return 'There is no availability on the selected day. Please choose another day for your service.';
      }

      return `Select the arrival window that works best for you.`;
    }, [isMobile, items.length, loading]);

    const list = useMemo(() => (isMobile ? [selectedPriod] : periods), [isMobile, periods, selectedPriod]);

    const getArrivalWindow = useCallback(
      (data: AvailabilityResponseData) => {
        const jobStart = new Date(data.schedule.start);
        const jobDate = data.schedule.start.split(' ');

        const arrival = arrivalWindows.find(window =>
          moment(jobStart).isBetween(
            jobDate[0] + ' ' + window.timestamp_start,
            jobDate[0] + ' ' + window.timestamp_end,
            undefined,
            '[]',
          ),
        );

        return arrival?.window_duration;
      },
      [arrivalWindows],
    );

    return (
      <Grid container spacing={2}>
        {!!arrivalText && (
          <Grid item xs={12}>
            <Typography>{arrivalText}</Typography>
          </Grid>
        )}

        {showMostUsed && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {!loading &&
                !!mostUsed.length &&
                mostUsed.map((item, index) => (
                  <Grid key={index} item xs={12} sm={6} md={3}>
                    <TimeSlotButton
                      isSelected={isSelected(item)}
                      duration={getArrivalWindow(item)}
                      onSelect={onSelect}
                      timeSlot={item}
                      disabled={disabled}
                    />
                  </Grid>
                ))}

              {loading &&
                [1, 2, 3, 4].map(item => (
                  <Grid key={item} item xs={12} sm={6} md={3}>
                    <TimeSlotSkeleton />
                  </Grid>
                ))}
            </Grid>

            {items.length > 4 && (
              <Box textAlign="center" mt={2}>
                <Button color="primary" onClick={() => setShowMostUsed(false)} id="ob-form-view-more-times">
                  View more times
                </Button>
              </Box>
            )}
          </Grid>
        )}

        {!showMostUsed && (
          <>
            {isMobile && (
              <Grid item xs={12}>
                <PeriodSelector periods={periods} selected={selectedPriod} onSelect={selectPeriod} />
              </Grid>
            )}

            <Grid item xs={12}>
              <Grid container spacing={isTablet ? 2 : 6}>
                {list.map(key => (
                  <Grid key={key} item xs={12} sm={4}>
                    <AvailabilityColumn
                      label={key}
                      list={sortedHours[key]}
                      loading={loading}
                      onSelect={onSelect}
                      isSelected={isSelected}
                      getArrivalWindow={getArrivalWindow}
                      disabled={disabled}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    );
  },
);
