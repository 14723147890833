import { memo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { CalendarToday } from '@material-ui/icons';

import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { AppRoute } from 'routing/AppRoute.enum';
import { RescheduleAppointmentProps } from 'app/rescheduleAppointment/RescheduleAppointment.types';
import { ActionButton } from 'ui/actionButton/ActionButton';

export const RescheduleAppointmentButton = memo(({ appointment }: { appointment: Appointment }) => {
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return (
      <Redirect
        to={{ pathname: AppRoute.reschedule, state: { appointment: appointment } as RescheduleAppointmentProps }}
      />
    );
  }

  return (
    <ActionButton
      icon={<CalendarToday color="primary" />}
      text="Reschedule Appointment"
      onClick={() => setRedirect(true)}
    />
  );
});
