/* eslint-disable react/jsx-props-no-spreading */
import { memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormControl, FormHelperText, Typography, Grid } from '@material-ui/core';
import { Button, Input } from '@chhjit/react-components';

import { validatePhone, validateEmail } from 'utils/validate/Validate';

import { useStyles } from './UpdateAppointmentUserForm.styles';
import { UpdateAppointmentUserFormProps, UpdateAppointmentUserFormData } from './UpdateAppointmentUserForm.types';

export const UpdateAppointmentUserForm = memo(({ user, onSubmit }: UpdateAppointmentUserFormProps) => {
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UpdateAppointmentUserFormData>({ defaultValues: user });

  const submit = (values: UpdateAppointmentUserFormData) => {
    setIsLoading(true);
    onSubmit(values);
  };
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography variant="h3" color="secondary">
          Edit Info
        </Typography>
      </Grid>

      <Grid item>
        <form onSubmit={handleSubmit(submit)}>
          <FormControl className={styles.formControl} error={!!errors.first_name?.message}>
            <Input
              {...register('first_name', {
                required: 'This field is required',
                maxLength: { value: 50, message: 'This field can have max. 50 characters' },
              })}
              size="small"
              label="First name"
              fullWidth
            />

            <div className={styles.helperContainer}>
              <FormHelperText>{errors.first_name && errors.first_name.message}</FormHelperText>
            </div>
          </FormControl>
          <FormControl className={styles.formControl} error={!!errors.last_name?.message}>
            <Input
              {...register('last_name', {
                required: 'This field is required',
                maxLength: { value: 50, message: 'This field can have max. 50 characters' },
              })}
              size="small"
              label="Last name"
              fullWidth
            />
            <div className={styles.helperContainer}>
              <FormHelperText>{errors.last_name && errors.last_name.message}</FormHelperText>
            </div>
          </FormControl>
          <FormControl className={styles.formControl} error={!!errors.phone?.message}>
            <Input
              {...register('phone', {
                required: 'This field is required',
                validate: value => validatePhone(value) || 'Please provide correct phone number!',
              })}
              size="small"
              label="Phone number"
              mask="phone"
              fullWidth
            />

            <div className={styles.helperContainer}>
              <FormHelperText>{errors.phone && errors.phone.message}</FormHelperText>
            </div>
          </FormControl>
          <FormControl className={styles.formControl} error={!!errors.email?.message}>
            <Input
              {...register('email', {
                required: 'This field is required',
                validate: value => validateEmail(value) || 'Please provide correct email!',
                maxLength: { value: 50, message: 'This field can have max. 50 characters' },
              })}
              size="small"
              label="Email"
              fullWidth
            />

            <div className={styles.helperContainer}>
              <FormHelperText>{errors.email && errors.email.message}</FormHelperText>
            </div>
          </FormControl>

          <Button buttonType="twoTone" type="submit" isLoading={isLoading} fullWidth>
            Update
          </Button>
        </form>
      </Grid>
    </Grid>
  );
});
