import { memo } from 'react';
import { useDialogDispatch } from '@chhjit/react-components';
import { LocalShipping } from '@material-ui/icons';

import { Appointment } from 'context/appointments/appointmentsContext/AppointmentsContext.types';
import { ActionButton } from 'ui/actionButton/ActionButton';

import { CheckEtaDialog } from './checkEtaDialog/CheckEtaDialog';

export const CheckEtaButton = memo(({ appointment }: { appointment: Appointment }) => {
  const setDialog = useDialogDispatch();

  const handleCheckEta = async () => {
    setDialog({ open: true, variant: 'schedule', dialogContent: <CheckEtaDialog appointment={appointment} /> });
  };

  return <ActionButton icon={<LocalShipping color="primary" />} text="Check ETA" onClick={() => handleCheckEta()} />;
});
