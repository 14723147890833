import { colors } from '@chhjit/react-components';
import { SvgIcon } from '@material-ui/core';
import { memo } from 'react';

export const LowHouseIcon = memo(({ className }: { className?: string }) => (
  <SvgIcon className={className} width="50" height="27" viewBox="0 0 50 27" fill="none">
    <g clipPath="url(#clip0)">
      <path
        d="M46.3578 15.9115C44.9273 15.9115 43.6823 16.7415 43.0916 17.9475V21.1552C43.6579 22.3025 44.8101 23.1081 46.1528 23.1813V25.4077C46.1528 25.5981 46.309 25.7494 46.4945 25.7494C46.685 25.7494 46.8363 25.5932 46.8363 25.4077V23.1569C48.6184 22.9226 49.9952 21.3944 49.9952 19.5538C50.0001 17.5422 48.3645 15.9115 46.3578 15.9115Z"
        fill={colors.primary.light}
      />
      <path
        d="M23.3181 0H10.8291C10.7314 0 10.6387 0.0341763 10.5654 0.0927644L1.79675 7.37233C1.66493 7.48462 1.61122 7.66527 1.66981 7.82638C1.7284 7.9875 1.88463 8.09979 2.05552 8.09979H3.83269V24.4556C3.83269 24.6802 4.01822 24.8657 4.2428 24.8657H30.6953H42.6765C42.9011 24.8657 43.0867 24.6802 43.0867 24.4556V21.1552C42.8474 20.6718 42.7156 20.125 42.7156 19.5538C42.7156 18.9776 42.8474 18.4357 43.0867 17.9475V12.7234C43.0867 12.4939 42.9011 12.3132 42.6765 12.3132H31.1054V8.10468H32.575C32.7508 8.10468 32.907 7.99238 32.9656 7.82638C33.0242 7.66038 32.9705 7.47974 32.8338 7.36744L23.572 0.087882C23.4987 0.029294 23.4109 0 23.3181 0Z"
        fill={colors.primary.light}
      />
      <path
        d="M43.0915 17.9475C42.8523 18.4308 42.7205 18.9777 42.7205 19.5538C42.7205 20.1299 42.8572 20.6718 43.0915 21.1552V17.9475Z"
        fill={colors.primary.light}
      />
      <path
        d="M8.76867 24.4312H5.7758V17.4788H8.76867V24.4312ZM9.18367 16.6585H5.37057C5.14598 16.6585 4.96045 16.8441 4.96045 17.0687V24.8462C4.96045 25.0708 5.14598 25.2563 5.37057 25.2563H9.18367C9.40826 25.2563 9.59379 25.0708 9.59379 24.8462V17.0687C9.59379 16.8392 9.40826 16.6585 9.18367 16.6585Z"
        fill={colors.primary.main}
      />
      <path
        d="M40.621 25.6909H39.7813V16.6781C39.7813 16.4486 39.5957 16.2679 39.3712 16.2679H30.5439C30.3144 16.2679 30.1338 16.4535 30.1338 16.6781V25.6909H29.4649V14.7788H40.621V25.6909ZM38.961 17.8449H30.954V17.0882H38.961V17.8449ZM30.954 24.6704H38.961V25.4272H30.954V24.6704ZM38.961 18.8898H30.954V18.2551H38.961V18.8898ZM38.961 24.2603H30.954V23.5817H38.961V24.2603ZM38.961 23.1716H30.954V22.5613H38.961V23.1716ZM38.961 22.1512H30.954V21.4774H38.961V22.1512ZM30.954 19.2999H38.961V19.9785H30.954V19.2999ZM38.961 21.0624H30.954V20.3886H38.961V21.0624ZM14.3101 25.4272V21.3114C14.3101 20.5937 14.896 20.0078 15.6137 20.0078H15.9701C16.7122 20.0078 17.3177 20.6132 17.3177 21.3553V25.4272H14.3101ZM3.01241 9.74515H28.6398V14.3638V25.686H18.1379V21.3505C18.1379 20.1543 17.1663 19.1827 15.9701 19.1827H15.6137C14.442 19.1827 13.4899 20.1348 13.4899 21.3065V25.686H3.01241V9.74515ZM9.33015 2.46559H21.5311L29.7481 8.92003H29.0499H2.60229H1.55259L9.33015 2.46559ZM41.0312 13.9586H29.46V9.75003H30.9296C31.1054 9.75003 31.2616 9.63774 31.3202 9.47174C31.3788 9.30574 31.3251 9.12021 31.1835 9.0128L21.9266 1.73324C21.8533 1.67465 21.7655 1.64536 21.6727 1.64536H9.18368C9.08604 1.64536 8.99327 1.67953 8.92004 1.73812L0.14648 9.01768C0.0146567 9.12997 -0.039049 9.31062 0.0244213 9.47174C0.0830094 9.63285 0.239244 9.74515 0.410126 9.74515H2.1873V26.101C2.1873 26.3304 2.37282 26.5111 2.59741 26.5111H29.0499H41.0312C41.2557 26.5111 41.4413 26.3256 41.4413 26.101V14.3687C41.4462 14.1393 41.2606 13.9586 41.0312 13.9586Z"
        fill={colors.primary.main}
      />
      <path
        d="M17.2933 18.0061H14.3542V17.4299H17.2933V18.0061ZM17.7083 16.6097H13.9489C13.7195 16.6097 13.5388 16.7952 13.5388 17.0198V18.4211C13.5388 18.6505 13.7243 18.8312 13.9489 18.8312H17.7083C17.9329 18.8312 18.1184 18.6456 18.1184 18.4211V17.0198C18.1184 16.7904 17.9329 16.6097 17.7083 16.6097Z"
        fill={colors.primary.main}
      />
      <path
        d="M22.9665 17.4788H25.9593V24.4312H22.9665V17.4788ZM22.5564 25.2563H26.3695C26.594 25.2563 26.7796 25.0708 26.7796 24.8462V17.0687C26.7796 16.8441 26.594 16.6585 26.3695 16.6585H22.5564C22.3269 16.6585 22.1462 16.8441 22.1462 17.0687V24.8462C22.1462 25.0708 22.3269 25.2563 22.5564 25.2563Z"
        fill={colors.primary.main}
      />
      <path
        d="M8.76867 14.9106H5.7758V11.9178H8.76867V14.9106ZM9.18367 11.0975H5.37057C5.14598 11.0975 4.96045 11.2831 4.96045 11.5077V15.3208C4.96045 15.5502 5.14598 15.7309 5.37057 15.7309H9.18367C9.40826 15.7309 9.59379 15.5453 9.59379 15.3208V11.5077C9.59379 11.2831 9.40826 11.0975 9.18367 11.0975Z"
        fill={colors.primary.main}
      />
      <path
        d="M22.9665 11.9226H25.9593V14.9155H22.9665V11.9226ZM22.5564 15.7358H26.3695C26.594 15.7358 26.7796 15.5502 26.7796 15.3256V11.5125C26.7796 11.2879 26.594 11.1024 26.3695 11.1024H22.5564C22.3269 11.1024 22.1462 11.2879 22.1462 11.5125V15.3256C22.1462 15.5502 22.3269 15.7358 22.5564 15.7358Z"
        fill={colors.primary.main}
      />
      <path
        d="M14.2904 13.2262C14.2904 12.5085 14.8763 11.9226 15.594 11.9226H15.9504C16.6926 11.9226 17.298 12.5281 17.298 13.2702V14.2222H14.2953V13.2262H14.2904ZM13.8803 15.0425H17.7081C17.9327 15.0425 18.1182 14.8569 18.1182 14.6323V13.2702C18.1182 12.074 17.1466 11.1024 15.9504 11.1024H15.594C14.4223 11.1024 13.4702 12.0545 13.4702 13.2262V14.6323C13.4702 14.8569 13.6509 15.0425 13.8803 15.0425Z"
        fill={colors.primary.main}
      />
      <path
        d="M45.7425 23.5573V22.8786L46.8703 20.8183C46.9631 20.6523 46.8996 20.4424 46.7336 20.3545C46.5676 20.2617 46.3577 20.3252 46.2698 20.4912L45.7425 21.453V19.9004C45.7425 19.71 45.5912 19.5586 45.4008 19.5586C45.2103 19.5586 45.059 19.71 45.059 19.9004V22.1658L44.0044 21.0673C43.8726 20.9306 43.6578 20.9257 43.5211 21.0575C43.3843 21.1893 43.3795 21.4042 43.5113 21.5409L45.0004 23.0886L45.059 23.1276V23.5817C43.5259 23.4743 42.3054 22.2049 42.3054 20.6474C42.3054 19.0167 43.6333 17.6936 45.2592 17.6936C46.8899 17.6936 48.213 19.0167 48.213 20.6474C48.2179 22.1121 47.1437 23.3229 45.7425 23.5573ZM48.9014 20.6474C48.9014 18.6408 47.2658 17.0052 45.2592 17.0052C43.2525 17.0052 41.6169 18.6359 41.6169 20.6474C41.6169 22.5857 43.1402 24.1725 45.0541 24.2799V26.5062C45.0541 26.6966 45.2055 26.848 45.3959 26.848C45.5863 26.848 45.7376 26.6917 45.7376 26.5062V24.2555C47.5197 24.0162 48.9014 22.4929 48.9014 20.6474Z"
        fill={colors.primary.main}
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="50" height="26.848" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
));
