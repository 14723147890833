/* eslint-disable react/jsx-props-no-spreading */
import { Grid, Typography, FormControl, FormHelperText } from '@material-ui/core';
import { memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, TextArea, useDialogDispatch } from '@chhjit/react-components';

import { ReviewFormData } from '../ReviewAppointmentDialog.types';
import { useReviewAppointment } from 'hooks/useReviewAppointment/useReviewAppointment';
import { ReviewAppointmentThankYou } from '../reviewAppointmentThankYou/ReviewAppointmentThankYou';

import { ReviewAppointmentHappyProps } from './ReviewAppointmentHappy.types';
import { useStyles } from './ReviewAppointmentHappy.styles';

export const ReviewAppointmentHappy = memo(({ appointment, reviewValue }: ReviewAppointmentHappyProps) => {
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ReviewFormData>();
  const reviewAppointment = useReviewAppointment();
  const setDialog = useDialogDispatch();

  const submit = async (values: ReviewFormData) => {
    setIsLoading(true);
    await reviewAppointment(reviewValue, values.message, '', appointment.id);
    setDialog({
      open: true,
      variant: 'schedule',
      dialogContent: <ReviewAppointmentThankYou appointment={appointment} reviewType={'satisfied'} />,
    });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Grid container direction="row" alignItems="center" spacing={2} className={styles.dialogContainer}>
        <Grid item xs={12}>
          <Typography variant="h4" color="secondary">
            We'd love to hear more from you
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={styles.feedbackInput} error={!!errors.message?.message}>
            <TextArea
              {...register('message', {
                required: 'This field is required',
                minLength: {
                  value: 5,
                  message: 'This field should have at least 5 characters',
                },
              })}
              size={'large'}
              variant={'outlined'}
              fullWidth
              placeholder={'I really liked it when my HUNKS did...'}
              rows={5}
            />

            <div>
              <FormHelperText>{errors.message && errors.message.message}</FormHelperText>
            </div>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button buttonType="twoTone" type="submit" isLoading={isLoading}>
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
});
